<template>
  <Toast />
  <div id="app">
    <div v-if="this.$store.getters.loading" id="overlay">
      <div
        tabindex="0"
        class="vl-overlay vl-active vl-full-page"
        aria-busy="true"
        aria-label="Loading"
        style=""
      >
        <div class="vl-background" style="background: rgb(255, 255, 255)"></div>
        <div class="vl-icon">
          <svg
            viewBox="0 0 120 30"
            xmlns="http://www.w3.org/2000/svg"
            fill="#11101d"
            width="128"
            height="128"
          >
            <circle cx="15" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              ></animate>
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              ></animate>
            </circle>
            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
              <animate
                attributeName="r"
                from="9"
                to="9"
                begin="0s"
                dur="0.8s"
                values="9;15;9"
                calcMode="linear"
                repeatCount="indefinite"
              ></animate>
              <animate
                attributeName="fill-opacity"
                from="0.5"
                to="0.5"
                begin="0s"
                dur="0.8s"
                values=".5;1;.5"
                calcMode="linear"
                repeatCount="indefinite"
              ></animate>
            </circle>
            <circle cx="105" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              ></animate>
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              ></animate>
            </circle>
          </svg>
        </div>
      </div>
    </div>
    <div>
      <Sidebar
        :is-menu-open="false"
        menu-title="FIFe.CZ"
        menu-logo=""
        :menu-items="[
          {
            link: '/',
            name: 'Domů',
            tooltip: 'Domů',
            icon: 'bx-home',
          },
          {
            link: '/litter/new',
            name: 'Krycí list',
            tooltip: 'Krycí list',
            icon: 'bx-book-add',
          },
          {
            link: '/stations',
            name: 'Chovatelské stanice',
            tooltip: 'Chovatelské stanice',
            icon: 'bx-home-heart',
          },
          {
            link: '/members',
            name: 'Chovatelé',
            tooltip: 'Chovatelé',
            icon: 'bx-body',
          },
          {
            link: '/breeds',
            name: 'Plemena',
            tooltip: 'Plemena',
            icon: 'bx-category',
          },
          {
            link: '/colors',
            name: 'Barvy',
            tooltip: 'Barvy',
            icon: 'bx-palette',
          },
          {
            link: '/tests',
            name: 'Testy',
            tooltip: 'Testy',
            icon: 'bx-shield',
          },
          {
            link: '/titles',
            name: 'Tituly',
            tooltip: 'Tituly',
            icon: 'bx-certification',
          },
          {
            link: '/breeds/sisters',
            name: 'Sesterská plemena',
            tooltip: 'Sesterská plemena',
            icon: 'bx-git-compare',
          },
          {
            link: '/import',
            name: 'Import',
            tooltip: 'Import',
            icon: 'bx-import',
          },
          {
            link: '/cats',
            name: 'Kočky',
            tooltip: 'Kočky',
            icon: 'bx-book-content',
          },
          {
            link: '/cat/titles',
            name: 'Tituly koček',
            tooltip: 'Tituly koček',
            icon: 'bxs-certification',
          },
          {
            link: '/cat/tests',
            name: 'Testy koček',
            tooltip: 'Testy koček',
            icon: 'bxs-shield',
          },
          {
            link: '/litter/registrations',
            name: 'Odeslané KL',
            tooltip: 'Odeslané KL',
            icon: 'bx-mail-send',
          },
        ]"
        :is-search="true"
        search-placeholder="Search..."
        search-tooltip="Search"
        :profile="null"
        :is-exit-button="true"
      />
    </div>
    <router-view class="page" v-if="!this.$store.getters.loadingData" />
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import Toast from "primevue/toast";

export default {
  name: "App",
  components: { Sidebar, Toast },
  data() {
    return {};
  },
  beforeCreate() {
    this.$store.dispatch("loadingOn");
    this.$store.dispatch("loadBreeds");
  },
  mounted() {
    this.$store.dispatch("loadingOff");
  },
};
</script>

<style lang="scss">
@import "./assets/styles/global.scss";
.page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 56px);
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.vl-overlay.vl-full-page {
  z-index: 9999;
  position: fixed;
}
.vl-overlay.vl-active {
  display: flex;
}
.vl-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
}
</style>

<!--<style lang="scss">-->
<!--@import "./assets/styles/global.scss";-->
<!--.page {-->
<!--  display: flex;-->
<!--  justify-content: center;-->
<!--  flex-direction: column;-->
<!--  align-items: center;-->
<!--  min-height: calc(100vh - 56px);-->
<!--}-->

<!--#app {-->
<!--  font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--}-->

<!--nav {-->
<!--  padding: 30px;-->

<!--  a {-->
<!--    font-weight: bold;-->
<!--    color: #2c3e50;-->

<!--    &.router-link-exact-active {-->
<!--      color: #42b983;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
