<template>
  <div>
    <!-- First name -->
    <div class="field">
      <div class="p-float-label">
        <InputText
          id="name"
          v-model="member.firstName"
          :class="{
            'p-invalid': v$.member.firstName.$invalid,
          }"
          required
        />
        <label
          for="name"
          :class="{
            'p-error': v$.member.firstName.$invalid,
          }"
        >
          Name
        </label>
      </div>
      <VuelidateError :errors="v$.member.firstName.$errors" />
    </div>

    <!-- Last name -->
    <div class="field">
      <div class="p-float-label">
        <InputText
          id="surname"
          v-model="member.lastName"
          :class="{
            'p-invalid': v$.member.lastName.$invalid,
          }"
          required
        />
        <label
          for="surname"
          :class="{ 'p-error': v$.member.lastName.$invalid }"
        >
          Surname
        </label>
      </div>
      <VuelidateError :errors="v$.member.lastName.$errors" />
    </div>

    <!-- Czech member -->
    <div class="field" v-if="showCountry">
      <div class="field-checkbox">
        <Checkbox
          id="fromCzech"
          name="fromCzech"
          value="True"
          v-model="member.czMember"
          :binary="true"
        />
        <label for="fromCzech"> Czech member </label>
      </div>
    </div>

    <!-- Member number -->
    <div v-if="member.czMember" class="field">
      <div class="p-float-label">
        <InputText
          id="number"
          v-model="member.number"
          :class="{
            'p-invalid': v$.member.number.$invalid,
          }"
          :required="member.czMember"
        />
        <label for="number" :class="{ 'p-error': v$.member.number.$invalid }">
          Member number
        </label>
      </div>
      <VuelidateError :errors="v$.member.number.$errors" />
    </div>

    <!-- Country (for czech not members) -->
    <!-- Breed  -->
    <div v-if="!member.czMember && showCountry" class="field">
      <div class="p-float-label">
        <Dropdown
          id="country"
          v-model="country"
          :options="countries"
          optionLabel="nameEnCommon"
          :required="!member.czMember"
          :chage="updateCountry()"
        />
        <label
          for="breed"
          :class="{ 'p-error': v$.member.country.$invalid }"
          :style="member.country ? '' : 'top: 35%'"
        >
          Country
        </label>
        <VuelidateError :errors="v$.member.country.$errors" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  numeric,
  maxLength,
  minLength,
  requiredIf,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import CountryService from "@/services/CountryService";
import VuelidateError from "@/components/VuelidateError.vue";

export default {
  components: {
    VuelidateError,
    InputText,
    Checkbox,
    Dropdown,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    showCountry: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      countries: [],
      country: null,
      member: {
        firstName: null,
        lastName: null,
        czMember: this.data ? this.data.cz : false,
        number: null,
        country: null,
      },
    };
  },
  mounted() {
    if (this.showCountry) {
      CountryService.getAll()
        .then((response) => {
          this.countries = response.data;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Problém při načítání států",
          });
        });
    }
    if (this.data) {
      this.member = this.data;
      this.member.czMember = this.data.cz;
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validationConfig: {
    $lazy: true,
    $autoDirty: true,
  },
  validations() {
    return {
      member: {
        firstName: { required },
        lastName: { required },
        number: {
          required: requiredIf(this.member.czMember),
          numeric: numeric,
          maxLength: maxLength(5),
          minLength: minLength(5),
        },
        country: { required: requiredIf(!this.member.czMember) },
      },
    };
  },
  methods: {
    async sendBreeder() {
      if (await this.v$.$validate()) {
        this.$emit("getBreeder", this.member);
      }
    },
    updateCountry() {
      if (this.country) this.member.country = { code2: this.country.code2 };
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
