import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

export default {
  getAll() {
    return apiClient.get("/station/all");
  },
  getAllNames() {
    return apiClient.get("/station/all/names");
  },
  create(station: { name: string }) {
    return apiClient.post("/station/new", station);
  },
  update(station: { name: string; id: string }) {
    return apiClient.put("/station/update", station);
  },
};
