<template>
  <pre>
    {{ data }}
  </pre>
</template>

<script>
export default {
  name: "PedigreeView",
  data() {
    return {
      data: {
        member: {
          requireEmail: false,
          firstName: "Richard",
          lastName: "Panek",
          number: "12346",
          email: null,
        },
        father: {
          catTests: [{ KYL: "ok" }],
          number: "(CZ)SCHK LO 61/22/BSH",
          ems: {
            breed: {
              code: "BSH",
            },
            color: "s",
          },
          chip: "dsf",
          czech: false,
          father: {
            catTests: [{ KYL: "ok" }],
            number: "(CZ)SCHK LO 61/22/BSH",
            ems: {
              breed: {
                code: "BSH",
              },
              color: "s",
            },
            chip: "dsf",
            czech: false,
            father: {
              catTests: [{ KYL: "ok" }],
              number: "(CZ)SCHK LO 61/22/BSH",
              ems: {
                breed: {
                  code: "BSH",
                },
                color: "s",
              },
              chip: "dsf",
              czech: false,
              father: {
                catTests: [{ KYL: "ok" }],
                number: "(CZ)SCHK LO 61/22/BSH",
                ems: {
                  breed: {
                    code: "BSH",
                  },
                  color: "s",
                },
                chip: "dsf",
                czech: false,
              },
              mother: {
                catTests: [{ KYL: "ok" }],
                number: "(CZ)SCHK LO 71/22/BSH",
                ems: {
                  breed: {
                    code: "BSH",
                  },
                  color: "s",
                },
                chip: "234567",
                czech: false,
              },
            },
            mother: {
              catTests: [{ KYL: "ok" }],
              number: "(CZ)SCHK LO 71/22/BSH",
              ems: {
                breed: {
                  code: "BSH",
                },
                color: "s",
              },
              chip: "234567",
              czech: false,
              father: {
                catTests: [{ KYL: "ok" }],
                number: "(CZ)SCHK LO 61/22/BSH",
                ems: {
                  breed: {
                    code: "BSH",
                  },
                  color: "s",
                },
                chip: "dsf",
                czech: false,
              },
              mother: {
                catTests: [{ KYL: "ok" }],
                number: "(CZ)SCHK LO 71/22/BSH",
                ems: {
                  breed: {
                    code: "BSH",
                  },
                  color: "s",
                },
                chip: "234567",
                czech: false,
              },
            },
          },
          mother: {
            catTests: [{ KYL: "ok" }],
            number: "(CZ)SCHK LO 71/22/BSH",
            ems: {
              breed: {
                code: "BSH",
              },
              color: "s",
            },
            chip: "234567",
            czech: false,
          },
        },
        mother: {
          catTests: [{ KYL: "ok" }],
          number: "(CZ)SCHK LO 71/22/BSH",
          ems: {
            breed: {
              code: "BSH",
            },
            color: "s",
          },
          chip: "234567",
          czech: false,
        },
        differentFatherOwner: false,
        fatherOwner: null,
        litter: {
          matingDate: "2022-09-26T22:00:00.000Z",
          birthdate: "2022-09-19T22:00:00.000Z",
          kittenCount: 1,
          deadMales: 0,
          deadFemales: 0,
        },
        kittens: [{ name: "a", sex: "m", ems: [Object], chip: "dsf" }],
      },
    };
  },
};
</script>

<style scoped></style>
