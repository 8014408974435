<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Nový"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
        </template>
      </Toolbar>
      <DataTable
        ref="dt"
        :value="items"
        v-model:selection="selectedItems"
        dataKey="name"
        :paginator="true"
        :rows="10"
        showGridlines
        v-model:filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Zobrazen {first}. až {last}. záznam z celkového počtu {totalRecords} záznamu"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :globalFilterFields="[
          'name',
          'owners',
          'cats',
          'created',
          'suspended',
          'ended',
        ]"
        selectionMode="multiple"
        sortMode="multiple"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justiify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Chovatelské stanice</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Hledat..."
              />
            </span>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          style="width: 3rem"
          :exportable="false"
        />

        <Column field="name" header="Název" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle názvu"
            />
          </template>
        </Column>

        <Column field="number" header="Číslo" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle čísla"
            />
          </template>
        </Column>

        <Column
          field="owners"
          header="Počet majitelů"
          :sortable="true"
          dataType="numeric"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel }">
            <Slider
              v-model="filterModel.value"
              range
              class="m-3"
              :min="0"
              :max="maxOwners"
            />
            <div class="flex align-items-center justify-content-between px-2">
              <span>
                {{ filterModel.value ? filterModel.value[0] : 0 }}
              </span>
              <span>
                {{ filterModel.value ? filterModel.value[1] : maxOwners }}
              </span>
            </div>
          </template>
        </Column>

        <Column
          field="cats"
          header="Narozeno koček"
          :sortable="true"
          dataType="numeric"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel }">
            <Slider
              v-model="filterModel.value"
              range
              class="m-3"
              :min="0"
              :max="maxCats"
            />
            <div class="flex align-items-center justify-content-between px-2">
              <span>
                {{ filterModel.value ? filterModel.value[0] : 0 }}
              </span>
              <span>
                {{ filterModel.value ? filterModel.value[1] : maxCats }}
              </span>
            </div>
          </template>
        </Column>

        <Column
          header="Vytvořeno"
          field="created"
          dataType="date"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ formatDate(data.created) }}
          </template>
          <template #filter="{ filterModel }">
            <Calendar
              v-model="filterModel.value"
              dateFormat="dd.mm.yy"
              placeholder="dd/mm/yyyy"
            />
          </template>
        </Column>

        <Column
          header="Pozastaveno"
          field="suspended"
          dataType="date"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ formatDate(data.suspended) }}
          </template>
          <template #filter="{ filterModel }">
            <Calendar
              v-model="filterModel.value"
              dateFormat="dd.mm.yy"
              placeholder="dd/mm/yyyy"
            />
          </template>
        </Column>

        <Column
          header="Ukončeno"
          field="ended"
          dataType="date"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ formatDate(data.ended) }}
          </template>
          <template #filter="{ filterModel }">
            <Calendar
              v-model="filterModel.value"
              dateFormat="dd.mm.yy"
              placeholder="dd/mm/yyyy"
            />
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editItem(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="infoDialog"
      :style="{ width: '450px' }"
      header="Chovatelská stanice"
      :modal="true"
      class="p-fluid"
    >
      <div class="field">
        <label for="name">Název</label>
        <InputText
          id="name"
          v-model.trim="item.name"
          required="true"
          :class="{ 'p-invalid': submitted && !item.name }"
        />
        <VuelidateError :errors="v$.item.name.$errors" />
      </div>

      <div class="field">
        <label for="number">Číslo chovatelské stanice stanice</label>
        <InputText
          id="number"
          v-model.trim="item.number"
          required="true"
          :class="{ 'p-invalid': submitted && !item.number }"
          pattern="^\d{5}$"
          maxlength="5"
          minlength="5"
        />
        <VuelidateError :errors="v$.item.number.$errors" />
      </div>

      <div class="field">
        <label for="name">Pozastaveno</label>
        <Calendar
          dateFormat="dd.mm.yy"
          id="date"
          v-model="item.suspended"
          :showIcon="true"
        />
      </div>

      <div class="field">
        <label for="name">Ukončeno</label>
        <Calendar
          dateFormat="dd.mm.yy"
          id="date"
          v-model="item.ended"
          :showIcon="true"
        />
      </div>

      <h3 class="mb-2 md:m-0 p-as-md-center">Adresa</h3>

      <div class="field">
        <label for="number">Ulice</label>
        <InputText
          id="street"
          v-model.trim="item.street"
          required="true"
          :class="{ 'p-invalid': submitted && !item.street }"
        />
        <VuelidateError :errors="v$.item.street.$errors" />
      </div>

      <div class="field">
        <label for="number">Číslo domu</label>
        <InputText
          id="houseNumber"
          v-model.trim="item.houseNumber"
          required="true"
          :class="{ 'p-invalid': submitted && !item.houseNumber }"
        />
        <VuelidateError :errors="v$.item.houseNumber.$errors" />
      </div>

      <div class="field">
        <label for="number">Město</label>
        <InputText
          id="town"
          v-model.trim="item.town"
          required="true"
          :class="{ 'p-invalid': submitted && !item.town }"
        />
        <VuelidateError :errors="v$.item.town.$errors" />
      </div>

      <div class="field">
        <label for="number">Poštovní směrovací číslo</label>
        <InputText
          id="postalCode"
          v-model.trim="item.postalCode"
          required="true"
          :class="{ 'p-invalid': submitted && !item.postalCode }"
        />
        <VuelidateError :errors="v$.item.postalCode.$errors" />
      </div>

      <div class="field">
        <label for="country">Země</label>
        <Dropdown
          id="country"
          v-model="item.country"
          :options="countries"
          optionLabel="nameEnCommon"
          :filter="true"
          placeholder="Vyberte zemi"
          required="true"
          :showClear="true"
          :class="{ 'p-invalid': submitted && !item.country }"
        />
        <VuelidateError :errors="v$.item.country.$errors" />
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Slider from "primevue/slider";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import StationService from "@/services/StationService";
import CountryService from "@/services/CountryService";
import VuelidateError from "@/components/VuelidateError.vue";

export default {
  components: {
    VuelidateError,
    Button,
    Toolbar,
    InputText,
    Column,
    DataTable,
    Dialog,
    Slider,
    Calendar,
    Dropdown,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      infoDialog: false,
      items: null,
      item: {},
      selectedItems: null,
      submitted: false,
      countries: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        owners: { value: null, matchMode: FilterMatchMode.BETWEEN },
        cats: { value: null, matchMode: FilterMatchMode.BETWEEN },
        created: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        suspended: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        ended: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
      },
      service: StationService,
    };
  },
  validations() {
    return {
      item: {
        name: { required },
        number: { required },
        street: { required },
        houseNumber: { required },
        town: { required },
        postalCode: { required },
        country: { required },
      },
    };
  },
  created() {
    this.fetchItems();
    CountryService.getAll()
      .then((response) => {
        this.countries = response.data;
      })
      .catch(() => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Error in countries loading",
        });
      });
  },
  computed: {
    maxCats() {
      return Math.max(...this.items.map((o) => o.cats));
    },
    maxOwners() {
      return Math.max(...this.items.map((o) => o.owners));
    },
  },
  methods: {
    formatDate(value) {
      if (value) {
        const event = new Date(value);
        return event.toLocaleDateString("cs-CZ", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else return null;
    },
    openNew() {
      this.item = {};
      this.submitted = false;
      this.infoDialog = true;
    },
    hideDialog() {
      this.infoDialog = false;
      this.submitted = false;
    },
    async saveItem() {
      this.submitted = true;
      if (await this.v$.$validate()) {
        if (this.item.id) {
          return this.updateItem();
        } else {
          return this.createItem();
        }
      }
    },
    editItem(item) {
      this.item = { ...item };
      this.infoDialog = true;
    },
    confirmDeleteOne(item) {
      this.item = item;
      this.deleteOneDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteMoreDialog = true;
    },
    // CHANGE TO WORK PROPERLY
    fetchItems() {
      this.service
        .getAll()
        .then((response) => {
          this.items = response.data;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    createItem() {
      this.service
        .create(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Organization created",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    updateItem() {
      this.service
        .update(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Organization updated",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "primevue/resources/themes/saga-blue/theme.css";
@import "primevue/resources/primevue.min.css";
@import "primeicons/primeicons.css";

.page {
  overflow-x: auto;
  flex-wrap: wrap;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
