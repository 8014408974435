import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

export default {
  doesRequireEmail(breeder: {
    firstName: string;
    lastName: string;
    number: string;
  }) {
    return apiClient.post("/breeder/require/email", breeder);
  },
};
