import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Breed {
  nameCs: string;
  code: string;
  category: number;
  subcategory: string;
}

export default {
  getAll() {
    return apiClient.get("/ems/breeds");
  },

  create(data: Breed) {
    return apiClient.post("/ems/breed/new", data);
  },

  update(data: Breed) {
    return apiClient.put("/ems/breed/update", data);
  },
};
