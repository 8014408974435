import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Ems {
  breed: Breed;
  color: string;
}

interface Breed {
  code: string;
  colors: number;
}

interface Member {
  requireEmail: boolean;
  firstName: string;
  lastName: string;
  number: string;
  email: string;
}

interface Test {
  nameCs: string;
  code: string;
  file: File;
}
interface Parent {
  catTests: Test[];
  number: string;
  ems: Ems;
  foreign: boolean;
  chip: string;
  pedigree: File | null;
}

interface Country {
  code2: string;
}

interface Owner {
  firstName: string;
  lastName: string;
  number: string | null;
  czMember: boolean;
  country: Country | null;
}

interface Litter {
  matingDate: Date;
  birthdate: Date;
  kittenCount: number;
  deadMales: number;
  deadFemales: number;
}

interface Kitten {
  name: string;
  sex: string;
  ems: Ems;
  chip: string;
}

interface Matting {
  member: Member;
  father: Parent;
  mother: Parent;
  differentFatherOwner: boolean;
  fatherOwner: Owner | null;
  litter: Litter;
  kittens: Kitten[];
}

interface Parent {
  number: string;
  name: string;
  ems: Ems;
  foreign: boolean;
}

interface Parents {
  ff: Parent | null;
  fff: Parent | null;
  ffff: Parent | null;
  fffm: Parent | null;
  ffm: Parent | null;
  ffmf: Parent | null;
  ffmm: Parent | null;
  fm: Parent | null;
  fmf: Parent | null;
  fmff: Parent | null;
  fmfm: Parent | null;
  fmm: Parent | null;
  fmmf: Parent | null;
  fmmm: Parent | null;
  mf: Parent | null;
  mff: Parent | null;
  mfff: Parent | null;
  mffm: Parent | null;
  mfm: Parent | null;
  mfmf: Parent | null;
  mfmm: Parent | null;
  mm: Parent | null;
  mmf: Parent | null;
  mmff: Parent | null;
  mmfm: Parent | null;
  mmm: Parent | null;
  mmmf: Parent | null;
  mmmm: Parent | null;
}

interface UpdateLitter extends Litter {
  parents: Parents;
  id: string;
  force: boolean;

  part: string;
}

export default {
  async newLitter(data: Matting) {
    await apiClient.post("/litter/new", data);
    for (const test of data.father.catTests) {
      await apiClient.post("/test/cat", test, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    if (data.father.pedigree) {
      await apiClient.post(
        "/cat/pedigree/save",
        {
          number: data.father.number,
          file: data.father.pedigree,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
  },

  getAll() {
    return apiClient.get("/litter/all");
  },

  getLitter(id: string) {
    return apiClient.post("/litter/one", { id: id });
  },
  update(data: UpdateLitter) {
    return apiClient.post("litter/update", data);
  },
  getFile(id: string) {
    return apiClient.post(
      "/litter/pedigree",
      { id: id },
      {
        responseType: "blob",
      }
    );
  },
};
