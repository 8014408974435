<template>
  <div v-if="this.loaded">
    <h5>FF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.ff"
      @get-cat="
        (cat) => {
          this.parents.ff = cat;
          this.submitCat();
        }
      "
    />

    <h5>FFF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.fff"
      @get-cat="
        (cat) => {
          this.parents.fff = cat;
          this.submitCat();
        }
      "
    />

    <h5>FFFF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.ffff"
      @get-cat="
        (cat) => {
          this.parents.ffff = cat;
          this.submitCat();
        }
      "
    />

    <h5>FFFM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.fffm"
      @get-cat="
        (cat) => {
          this.parents.fffm = cat;
          this.submitCat();
        }
      "
    />

    <h5>FFM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.ffm"
      @get-cat="
        (cat) => {
          this.parents.ffm = cat;
          this.submitCat();
        }
      "
    />

    <h5>FFMF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.ffmf"
      @get-cat="
        (cat) => {
          this.parents.ffmf = cat;
          this.submitCat();
        }
      "
    />

    <h5>FFMM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.ffmm"
      @get-cat="
        (cat) => {
          this.parents.ffmm = cat;
          this.submitCat();
        }
      "
    />

    <h5>FM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.fm"
      @get-cat="
        (cat) => {
          this.parents.fm = cat;
          this.submitCat();
        }
      "
    />

    <h5>FMF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.fmf"
      @get-cat="
        (cat) => {
          this.parents.fmf = cat;
          this.submitCat();
        }
      "
    />

    <h5>FMFF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.fmff"
      @get-cat="
        (cat) => {
          this.parents.fmff = cat;
          this.submitCat();
        }
      "
    />

    <h5>FMFM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.fmfm"
      @get-cat="
        (cat) => {
          this.parents.fmfm = cat;
          this.submitCat();
        }
      "
    />

    <h5>FMM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.fmm"
      @get-cat="
        (cat) => {
          this.parents.fmm = cat;
          this.submitCat();
        }
      "
    />

    <h5>FMMF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.fmmf"
      @get-cat="
        (cat) => {
          this.parents.fmmf = cat;
          this.submitCat();
        }
      "
    />

    <h5>FMMM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.fmmm"
      @get-cat="
        (cat) => {
          this.parents.fmmm = cat;
          this.submitCat();
        }
      "
    />

    <h5>MF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.mf"
      @get-cat="
        (cat) => {
          this.parents.mf = cat;
          this.submitCat();
        }
      "
    />

    <h5>MFF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.mff"
      @get-cat="
        (cat) => {
          this.parents.mff = cat;
          this.submitCat();
        }
      "
    />

    <h5>MFFF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.mfff"
      @get-cat="
        (cat) => {
          this.parents.mfff = cat;
          this.submitCat();
        }
      "
    />

    <h5>MFFM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.mffm"
      @get-cat="
        (cat) => {
          this.parents.mffm = cat;
          this.submitCat();
        }
      "
    />

    <h5>MFM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.mfm"
      @get-cat="
        (cat) => {
          this.parents.mfm = cat;
          this.submitCat();
        }
      "
    />

    <h5>MFMF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.mfmf"
      @get-cat="
        (cat) => {
          this.parents.mfmf = cat;
          this.submitCat();
        }
      "
    />

    <h5>MFMM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.mfmm"
      @get-cat="
        (cat) => {
          this.parents.mfmm = cat;
          this.submitCat();
        }
      "
    />

    <h5>MM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.mm"
      @get-cat="
        (cat) => {
          this.parents.mm = cat;
          this.submitCat();
        }
      "
    />

    <h5>MMF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.mmf"
      @get-cat="
        (cat) => {
          this.parents.mmf = cat;
          this.submitCat();
        }
      "
    />

    <h5>MMFF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.mmff"
      @get-cat="
        (cat) => {
          this.parents.mmff = cat;
          this.submitCat();
        }
      "
    />

    <h5>MMFM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.mmfm"
      @get-cat="
        (cat) => {
          this.parents.mmfm = cat;
          this.submitCat();
        }
      "
    />

    <h5>MMM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.mmm"
      @get-cat="
        (cat) => {
          this.parents.mmm = cat;
          this.submitCat();
        }
      "
    />

    <h5>MMMF</h5>
    <ParentInput
      :male="true"
      :could-be-foreign="true"
      :data="parents.mmmf"
      @get-cat="
        (cat) => {
          this.parents.mmmf = cat;
          this.submitCat();
        }
      "
    />

    <h5>MMMM</h5>
    <ParentInput
      :male="false"
      :could-be-foreign="true"
      :data="parents.mmmm"
      @get-cat="
        (cat) => {
          this.parents.mmmm = cat;
          this.submitCat();
        }
      "
    />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import ParentInput from "@/components/ParentInput.vue";
import CatService from "@/services/CatService";

export default {
  components: {
    ParentInput,
  },
  props: {
    fatherNumber: {
      type: String,
      default: null,
    },
    motherNumber: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      requiredTests: [],
      doesRequirePedigree: false,
      loaded: false,
      parents: {
        ff: null,
        fff: null,
        ffff: null,
        fffm: null,
        ffm: null,
        ffmf: null,
        ffmm: null,
        fm: null,
        fmf: null,
        fmff: null,
        fmfm: null,
        fmm: null,
        fmmf: null,
        fmmm: null,
        mf: null,
        mff: null,
        mfff: null,
        mffm: null,
        mfm: null,
        mfmf: null,
        mfmm: null,
        mm: null,
        mmf: null,
        mmff: null,
        mmfm: null,
        mmm: null,
        mmmf: null,
        mmmm: null,
      },
      oldCat: JSON.stringify(this.cat),
    };
  },
  created() {
    this.$store.dispatch("loadingOn");
    const dto = {
      fatherNumber: this.fatherNumber,
      motherNumber: this.motherNumber,
    };

    CatService.getParents(dto)
      .then((response) => {
        this.parents = response.data;
        this.loaded = true;
      })
      .catch((err) => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
        });
      })
      .finally(() => this.$store.dispatch("loadingOff"));
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {};
  },
  methods: {
    async submitCat() {
      if (this.v$.$dirty && (await this.v$.$validate())) {
        this.$emit("getParents", this.parents);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
