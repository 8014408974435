import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface MemberWithoutId {
  firstName: string;
  lastName: string;
  number: string;
  country: { code2: string };
  email: string;
  organization: { name: string };
  breedingStation: { name: string };
}

interface Member extends MemberWithoutId {
  id: string;
}

export default {
  getAll() {
    return apiClient.get("/breeder/all");
  },
  create(member: MemberWithoutId) {
    return apiClient.post("/breeder/new", member);
  },
  update(member: Member) {
    return apiClient.put("/breeder/update", member);
  },
};
