<template>
  <div class="form-demo">
    <div class="flex justify-content-center">
      <div class="card">
        <h1>Import kočky</h1>
        <form @submit.prevent="handleSubmit" class="p-fluid">
          <h2>Kočka</h2>
          <div>
            <!-- Name -->
            <div class="field">
              <div class="p-float-label">
                <InputText
                  id="name"
                  v-model="cat.name"
                  :class="{
                    'p-invalid': v$.cat.name.$invalid,
                  }"
                  required
                />
                <label
                  for="name"
                  :class="{
                    'p-error': v$.cat.name.$invalid,
                  }"
                >
                  Name
                </label>
              </div>
              <VuelidateError :errors="v$.cat.name.$errors" />
            </div>

            <!-- Sex -->
            <h5>Sex</h5>
            <div class="field">
              <div class="p-float-label">
                <div class="field-radiobutton">
                  <RadioButton
                    inputId="male"
                    name="sex"
                    value="m"
                    v-model="cat.sex"
                  />
                  <label for="male">Male</label>
                </div>
              </div>
              <div class="p-float-label">
                <div class="field-radiobutton">
                  <RadioButton
                    inputId="female"
                    name="sex"
                    value="f"
                    v-model="cat.sex"
                  />
                  <label for="female">Female</label>
                </div>
              </div>
            </div>

            <!-- Birthdate -->
            <div class="field">
              <div class="p-float-label">
                <Calendar
                  id="date"
                  v-model="cat.birth"
                  :showIcon="true"
                  dateFormat="dd.mm.yy"
                  required
                  :class="{
                    'p-invalid': v$.cat.birth.$invalid,
                  }"
                />
                <label for="date">Birthday</label>
              </div>
              <VuelidateError :errors="v$.cat.birth.$errors" />
            </div>

            <!--  Part  -->
            <h5>Druh plemenné knihy</h5>
            <div class="field">
              <div class="p-float-label">
                <div class="field-radiobutton">
                  <RadioButton
                    inputId="LO"
                    name="part"
                    value="LO"
                    v-model="cat.part"
                  />
                  <label for="LO">LO</label>
                </div>
              </div>
              <div class="p-float-label">
                <div class="field-radiobutton">
                  <RadioButton
                    inputId="RX"
                    name="part"
                    value="RX"
                    v-model="cat.part"
                  />
                  <label for="female">RX</label>
                </div>
              </div>
            </div>

            <!--  Mother  -->
            <div class="field">
              <div class="p-float-label">
                <Dropdown
                  id="mother"
                  v-model="cat.mother"
                  :options="mothers"
                  optionLabel="pedigreeNumber"
                  :filter="true"
                />
                <label for="mother" :style="cat.mother ? '' : 'top: 35%'">
                  Matka
                </label>
              </div>
            </div>

            <!--  Father  -->

            <div class="field">
              <div class="p-float-label">
                <Dropdown
                  id="father"
                  v-model="cat.father"
                  :options="fathers"
                  optionLabel="pedigreeNumber"
                  :filter="true"
                />
                <label for="father" :style="cat.father ? '' : 'top: 35%'">
                  Otec
                </label>
              </div>
            </div>

            <!--  Breeder  -->

            <div class="field">
              <div class="p-float-label">
                <Dropdown
                  id="member"
                  v-model="cat.member"
                  :options="members"
                  optionLabel="number"
                  :filter="true"
                  :class="{
                    'p-invalid': v$.cat.member.$invalid,
                  }"
                />
                <label
                  for="father"
                  :class="{ 'p-error': v$.cat.member.$invalid }"
                  :style="cat.member ? '' : 'top: 35%'"
                >
                  Chovatel
                </label>
              </div>
              <VuelidateError :errors="v$.cat.member.$errors" />
            </div>

            <!--  Titles  -->
            <div class="field">
              <div class="p-float-label">
                <MultiSelect
                  id="titles"
                  v-model="cat.titles"
                  :options="titles"
                  optionLabel="abbreviation"
                  :filter="true"
                  class="multiselect-custom"
                />
                <label for="titles" :style="cat.titles ? '' : 'top: 35%'">
                  Tituly
                </label>
              </div>
            </div>

            <div class="field">
              <div class="field-checkbox">
                <Checkbox
                  inputId="foreign"
                  name="foreign"
                  v-model="cat.foreign"
                  :binary="true"
                  style="margin-right: 1em"
                />
                <label for="foreign"> Má původní číslo PK z jiné země </label>
              </div>
            </div>

            <div class="field" v-if="cat.foreign">
              <div class="p-float-label">
                <InputText
                  id="number"
                  v-model="cat.originalNumber"
                  :class="{
                    'p-invalid': v$.cat.originalNumber.$invalid,
                  }"
                  required
                />
                <label
                  for="number"
                  :class="{
                    'p-error': v$.cat.originalNumber.$invalid,
                  }"
                >
                  Number
                </label>
              </div>
              <VuelidateError :errors="v$.cat.originalNumber.$errors" />
            </div>

            <div class="field">
              <div class="field-checkbox">
                <Checkbox
                  inputId="czNum"
                  name="czNum"
                  v-model="czNum"
                  :binary="true"
                  style="margin-right: 1em"
                />
                <label for="czNum"> Již má české číslo PK </label>
              </div>
            </div>

            <div v-if="this.czNum">
              <CatNumberInput
                :could-be-foreign="false"
                :required="czNum"
                @get-number="
                  (sendNumber) => {
                    this.cat.number = sendNumber;
                  }
                "
              />
            </div>

            <EmsSelect
              @get-ems="
                (sendEms) => {
                  this.cat.ems = sendEms;
                }
              "
            />

            <!-- Chip -->
            <div class="field">
              <div class="p-float-label">
                <InputText
                  id="chip"
                  v-model="cat.chip"
                  :class="{
                    'p-invalid': v$.cat.chip.$invalid,
                  }"
                  required
                />
                <label
                  for="chip"
                  :class="{
                    'p-error': v$.cat.chip.$invalid,
                  }"
                >
                  Chip
                </label>
              </div>
              <VuelidateError :errors="v$.cat.chip.$errors" />
            </div>
          </div>
          <Button
            type="submit"
            :label="$t('litterRegistration.submit')"
            class="mt-2"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import EmsSelect from "@/components/litterInput/EmsSelect";
import VuelidateError from "@/components/VuelidateError.vue";
import RadioButton from "primevue/radiobutton";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import CatNumberInput from "@/components/CatNumberInput.vue";
import CatService from "@/services/CatService";
import MemberService from "@/services/MemberService";
import TitleService from "@/services/TitleService";

export default {
  components: {
    CatNumberInput,
    VuelidateError,
    EmsSelect,
    InputText,
    RadioButton,
    MultiSelect,
    Checkbox,
    Dropdown,
    Calendar,
    Button,
  },
  data() {
    return {
      czNum: false,
      mothers: [],
      fathers: [],
      titles: [],
      members: [],
      cat: {
        titles: [],
        member: null,
        mother: null,
        father: null,
        foreign: true,
        originalNumber: "",
        number: "",
        ems: null,
        chip: null,
        part: "LO",
        birth: null,
        name: "",
        sex: "m",
      },
    };
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      cat: {
        chip: { required },
        member: { required },
        foreign: { required },
        originalNumber: { required: requiredIf(this.cat.foreign) },
        number: { required: requiredIf(this.czNum) },
        ems: { required },
        part: { required },
        birth: { required },
        name: { required },
      },
    };
  },
  created() {
    this.part = "LO";
    CatService.getAllFemales()
      .then((response) => {
        this.mothers = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    CatService.getAllMales()
      .then((response) => {
        this.fathers = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    MemberService.getAll()
      .then((response) => {
        this.members = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    TitleService.getAll()
      .then((response) => {
        this.titles = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    async handleSubmit() {
      if (await this.v$.$validate()) {
        CatService.newImport(this.cat)
          .then(() => {
            this.cat = {
              titles: [],
              member: null,
              mother: null,
              father: null,
              foreign: true,
              originalNumber: "",
              number: "",
              ems: null,
              chip: null,
              part: "LO",
              birth: null,
              name: "",
              sex: "m",
            };
            this.$toast.add({
              severity: "success",
              summary: "Successfully saved",
              detail: "Kočka úspěšně vytvořena",
            });
          })
          .catch((err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    margin-bottom: 3em;
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
