<template>
  <div>
    <!-- Breed  -->
    <div class="field">
      <div class="p-float-label">
        <Dropdown
          id="breed"
          v-model="breed"
          :options="breeds"
          optionLabel="code"
          data-key="code"
          :chage="updateBreed()"
          :filter="true"
          :class="{
            'p-invalid': v$.breed.$invalid,
          }"
        />
        <label
          for="breed"
          :class="{ 'p-error': v$.breed.$invalid }"
          :style="breed ? '' : 'top: 35%'"
        >
          Plemeno
        </label>
        <VuelidateError :errors="v$.breed.$errors" />
      </div>
    </div>

    <!-- Color -->
    <div class="field" v-if="this.breed">
      <div class="p-float-label">
        <InputText
          id="color"
          v-model="color"
          :class="{
            'p-invalid': v$.color.$invalid,
          }"
          :required="colorsCount > 1"
          pattern="^(am|cm|em|pm|\*m|n|a|b|c|d|e|f|g|h|j|o|p|q|r|t|w|\*|)([sy])?( 0([123459]))?(( 1([12]))|( 2([12345])))?( 3([123]))?( 5([1234]))?( 6([1234567]))?( 7([12]))?( 8([1234]))?"
          :input="sendEms()"
        />
        <label for="color" :class="{ 'p-error': v$.color.$invalid }">
          Barva
        </label>
      </div>
      <VuelidateError :errors="v$.color.$errors" />
    </div>
  </div>
</template>

<script>
import { helpers, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import VuelidateError from "@/components/VuelidateError.vue";

let uid = 0;
export default {
  components: {
    VuelidateError,
    Dropdown,
    InputText,
  },
  props: {
    givenEms: {
      type: Object,
      default: () => {
        return {
          breed: null,
          color: {
            code: null,
          },
          unrecognized: false,
        };
      },
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    uid += 1;
    return {
      id: uid,
      ems: {
        color: this.givenEms.color.code,
        breed: this.givenEms.breed,
      },
      color: this.givenEms.color.code ? this.givenEms.color.code : null,
      breed: this.givenEms.breed,
      breeds: this.$store.getters.breeds,
      colors: null,
      colorsCount: 2,
      requiredFields: this.required,
    };
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      breed: {
        required: helpers.withMessage(
          "Plemeno je vyžadováno.",
          requiredIf(this.requiredFields)
        ),
      },
      color: {
        required: helpers.withMessage(
          "Barva je vyžadována.",
          requiredIf(this.colorsCount > 0 && this.requiredFields)
        ),
        pattern: helpers.withMessage(
          "Špatný formát barvy",
          helpers.regex(
            /^(am|cm|em|pm|\*m|n|a|b|c|d|e|f|g|h|j|o|p|q|r|t|w|\*|)([sy])?( 0([123459]))?(( 1([12]))|( 2([12345])))?( 3([123]))?( 5([1234]))?( 6([1234567]))?( 7([12]))?( 8([1234]))?$/
          )
        ),
      },
    };
  },
  methods: {
    updateBreed() {
      if (this.breed) {
        this.colorsCount = this.breed.colors;
        this.sendEms();
      }
    },
    async sendEms() {
      if (await this.v$.$validate()) {
        this.ems.breed = this.breed;
        this.ems.color = this.color;
        this.$emit("getEms", this.ems);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
