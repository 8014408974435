import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Ems {
  breed: { code: string; colors: number };
  color: string;
}

interface Import {
  titles: { abbreviation: string }[];
  member: { number: string };
  mother: { pedigreeNumber: string; number: string };
  father: { pedigreeNumber: string; number: string };
  foreign: boolean;
  originalNumber: string | null;
  number: string | null;
  ems: Ems;
  chip: string;
  part: string;
  birth: Date;
  name: string;
  sex: string;
}

export default {
  getAll() {
    return apiClient.get("/cat/");
  },
  getAllFemales() {
    return apiClient.get("/cat?sex=f");
  },
  getAllMales() {
    return apiClient.get("/cat?sex=m");
  },
  newImport(data: Import) {
    data.mother.number = data.mother?.pedigreeNumber;
    data.father.number = data.father?.pedigreeNumber;
    return apiClient.post("cat/import", data);
  },
  requirePedigree(data: { number: string }) {
    return apiClient.post("cat/require/pedigree", data);
  },
  getParents(data: { fatherNumber: string; motherNumber: string }) {
    return apiClient.post("cat/parents", data);
  },
  getFile(number: string) {
    return apiClient.post(
      "cat/pedigree",
      { number: number },
      {
        responseType: "blob",
      }
    );
  },
};
