<template>
  <div class="form-demo" v-if="data">
    <div class="flex justify-content-center">
      <div class="card">
        <h1>{{ $t("litterRegistration.litterRegistration") }}</h1>
        <form @submit.prevent="handleSubmit(false)" class="p-fluid">
          <LitterRegistration
            :data="this.data"
            :father-pedigree="true"
            :mother-pedigree="false"
            :search-tests="false"
            :show-cat-name="true"
            :show-email="false"
            :show-country="false"
            @get-litter="
              (sendData) => {
                this.getData = sendData;
              }
            "
          />

          <h2>Druh plemenné knihy</h2>
          <div class="field">
            <div class="p-float-label">
              <div class="field-radiobutton">
                <RadioButton
                  inputId="LO"
                  name="part"
                  value="LO"
                  v-model="part"
                />
                <label for="LO">LO</label>
              </div>
            </div>
            <div class="p-float-label">
              <div class="field-radiobutton">
                <RadioButton
                  inputId="RX"
                  name="part"
                  value="RX"
                  v-model="part"
                />
                <label for="female">RX</label>
              </div>
            </div>
          </div>

          <h2>Rodiče rodičů</h2>

          <ParentsInput
            :father-number="data.father.pedigreeNumber"
            :mother-number="data.mother.pedigreeNumber"
            @get-parents="
              (sendData) => {
                this.getData.parents = sendData;
              }
            "
          />
          <div v-if="submitCounter === 0">
            <Button
              type="submit"
              :label="$t('litterRegistration.submit')"
              class="mt-2"
            />
          </div>
          <div v-else>
            <Button
              type="submit"
              :label="$t('litterRegistration.submit')"
              class="mt-2"
              style="margin-bottom: 2em"
            />

            <div class="field">
              <div class="field-checkbox">
                <Checkbox
                  inputId="withoutChecks"
                  name="foreign"
                  v-model="withoutChecks"
                  :binary="true"
                  style="margin-right: 1em"
                />
                <label for="withoutChecks">
                  Vím, co dělám. Odeslat bez některých kontrol.
                </label>
              </div>
            </div>

            <Button
              type="button"
              :disabled="!withoutChecks"
              label="Odeslat BEZ KONTROL"
              class="mt-2"
              @click="handleSubmit(true)"
            />
          </div>

          <div
            v-if="
              data.status === 'processed' ||
              data.status === 'printed' ||
              data.status === 'finished'
            "
          >
            <h5>Rodokmeny</h5>
            <Button
              icon="pi pi-file-pdf"
              class="p-button-rounded p-button-danger"
              @click="downloadPedigrees()"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import Button from "primevue/button";
import LitterRegistration from "@/components/LitterRegistration.vue";
import LitterService from "@/services/LitterService";
import ParentsInput from "@/components/ParentsInput.vue";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";
import { required } from "@vuelidate/validators";

export default {
  components: {
    ParentsInput,
    LitterRegistration,
    Button,
    Checkbox,
    RadioButton,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: null,
      part: "LO",
      getData: null,
      submitCounter: 0,
      withoutChecks: false,
    };
  },
  beforeCreate() {
    LitterService.getLitter(this.id)
      .then((response) => {
        this.data = response.data;
      })
      .catch(() => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Problém při načítání informací o vrhu",
        });
      });
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      part: { required },
    };
  },
  methods: {
    async handleSubmit(force) {
      this.getData.part = this.part;
      this.getData.force = force && this.withoutChecks;
      this.getData.id = this.id;
      this.getData.member.requireEmail = false;
      this.getData.member.email = "null@null.cz";
      if (await this.v$.$validate()) {
        this.submitCounter++;
        await LitterService.update(this.getData)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Úspěch",
              detail: "Úspěšně uloženo",
            });
            this.data.status = "processed";
          })
          .catch((err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
            });
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: this.v$.$errors[0],
        });
      }
    },
    async downloadPedigrees() {
      this.$store.dispatch("loadingOn");
      LitterService.getFile(this.id)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.setAttribute("display", "none");
          link.setAttribute("target", "_blank");
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        })
        .finally(() => this.$store.dispatch("loadingOff"));
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    margin-bottom: 3em;
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
