<template>
  <div v-if="this.loaded">
    <h2>{{ $t("litterRegistration.breeder") }}</h2>

    <BreederInput
      :data="this.member"
      :show-email="this.showEmail"
      @get-breeder="
        (sendMember) => {
          this.member = sendMember;
          this.submit();
        }
      "
    />

    <h2>{{ $t("litterRegistration.father") }}</h2>

    <CatInput
      :data="this.father"
      :require-pedigree="fatherPedigree"
      :search-tests="searchTests"
      :show-name="showCatName"
      @get-cat="
        (sendFather) => {
          this.father = sendFather;
          this.submit();
        }
      "
      :could-be-foreign="true"
      :male="true"
    />

    <div class="field">
      <div class="field-checkbox">
        <Checkbox
          inputId="differentOwner"
          name="differentOwner"
          value="True"
          v-model="differentFatherOwner"
          :binary="true"
          style="margin-right: 1em"
        />
        <label for="differentOwner">
          {{ $t("litterRegistration.differentFatherOwner") }}
        </label>
      </div>
    </div>

    <FatherBreederInput
      :data="fatherOwner"
      v-if="differentFatherOwner"
      :show-country="showCountry"
      @get-breeder="
        (breeder) => {
          this.differentFatherOwner = breeder;
          this.submit;
        }
      "
    />

    <h2>{{ $t("litterRegistration.mother") }}</h2>

    <CatInput
      :data="this.mother"
      :require-pedigree="motherPedigree"
      :search-tests="searchTests"
      :show-name="showCatName"
      @get-cat="
        (sendMother) => {
          this.mother = sendMother;
          this.submit();
        }
      "
      :male="false"
    />

    <h2>{{ $t("litterRegistration.litterInfo") }}</h2>

    <LitterInput
      :data="litter"
      @get-litter="
        (sendLitter) => {
          this.litter = sendLitter;
          this.submit();
        }
      "
    />

    <div
      v-if="
        litter &&
        litter.kittenCount &&
        litter.kittenCount > litter.deadFemales + litter.deadMales
      "
    >
      <h2>{{ $t("litterRegistration.kittens") }}</h2>

      <div
        v-bind:key="n"
        v-for="n in litter.kittenCount - litter.deadFemales - litter.deadMales"
      >
        <h3>{{ $t("litterRegistration.kitten") + " " + n }}</h3>
        <KittenInput
          :data="kittens[n - 1] ? kittens[n - 1] : null"
          @get-kitten="
            (sendKitten) => {
              this.kittens[n - 1] = sendKitten;
              this.submit();
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import CatInput from "@/components/litterInput/CatInput";
import BreederInput from "@/components/litterInput/BreederInput";
import Checkbox from "primevue/checkbox";
import FatherBreederInput from "@/components/litterInput/FatherBreederInput";
import LitterInput from "@/components/litterInput/LitterInput";
import KittenInput from "@/components/litterInput/KittenInput";

export default {
  components: {
    KittenInput,
    LitterInput,
    BreederInput,
    CatInput,
    Checkbox,
    FatherBreederInput,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    fatherPedigree: {
      type: Boolean,
      default: true,
    },
    motherPedigree: {
      type: Boolean,
      default: false,
    },
    showCatName: {
      type: Boolean,
      default: false,
    },
    searchTests: {
      type: Boolean,
      default: true,
    },
    showEmail: {
      type: Boolean,
      default: true,
    },
    showCountry: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loaded: false,
      member: null,
      father: null,
      mother: null,
      differentFatherOwner: false,
      fatherOwner: null,
      litter: null,
      kittens: [],
    };
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {};
  },
  mounted() {
    if (!this.data) {
      this.member = null;
      this.father = null;
      this.mother = null;
      this.differentFatherOwner = false;
      this.fatherOwner = null;
      this.litter = null;
      this.kittens = [];
    } else {
      this.member = this.data.member;
      this.father = this.data.father;
      this.father.number = this.data.father.pedigreeNumber;
      this.father.foreign = this.data.father.czech;
      this.father.givenEms = this.father.ems;
      this.mother = this.data.mother;
      this.mother.number = this.data.mother.pedigreeNumber;
      this.mother.givenEms = this.mother.ems;
      this.differentFatherOwner = this.data.differentFatherOwner;
      this.fatherOwner = this.data.fatherOwner;
      this.litter = this.data.litter;
      this.kittens = this.data.kittens;
    }
    this.loaded = true;
  },
  methods: {
    async submit() {
      if (await this.v$.$validate()) {
        this.$emit("getLitter", {
          member: this.member,
          father: this.father,
          mother: this.mother,
          differentFatherOwner: this.differentFatherOwner || false,
          fatherOwner: this.fatherOwner || null,
          litter: this.litter,
          kittens: this.kittens,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    margin-bottom: 3em;

    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
