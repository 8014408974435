<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Nový"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
        </template>
      </Toolbar>
      <DataTable
        ref="dt"
        :value="items"
        v-model:selection="selectedItems"
        dataKey="number"
        :paginator="true"
        :rows="10"
        showGridlines
        v-model:filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Zobrazen {first}. až {last}. záznam z celkového počtu {totalRecords} záznamů"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :globalFilterFields="[
          'firstName',
          'lastName',
          'email',
          'number',
          'user',
          'breedingStation.name',
        ]"
        selectionMode="multiple"
        sortMode="multiple"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Chovatelé</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Hledat..."
              />
            </span>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          style="width: 3rem"
          :exportable="false"
        />

        <Column field="firstName" header="Jméno" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle jména"
            />
          </template>
        </Column>

        <Column field="lastName" header="Příjmení" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle příjmení"
            />
          </template>
        </Column>

        <Column field="number" header="Číslo" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle čísla"
            />
          </template>
        </Column>

        <Column field="email" header="Email" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle emailu"
            />
          </template>
        </Column>

        <Column
          field="user"
          header="Registrován"
          dataType="boolean"
          bodyClass="text-center"
          style="min-width: 8rem"
        >
          <template #body="{ data }">
            <i
              class="pi"
              :class="{
                'true-icon pi-check-circle': !!data.user,
                'false-icon pi-times-circle': !data.user,
              }"
            ></i>
          </template>
          <template #filter="{ filterModel }">
            <TriStateCheckbox v-model="filterModel.value" />
          </template>
        </Column>

        <Column field="breedingStation.name" header="Stanice" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle stanice"
            />
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editItem(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="infoDialog"
      :style="{ width: '450px' }"
      header="Chovatel"
      :modal="true"
      class="p-fluid"
    >
      <div class="field">
        <label for="name">Jméno</label>
        <InputText
          id="name"
          v-model.trim="item.firstName"
          required="true"
          :class="{ 'p-invalid': submitted && !item.firstName }"
        />
        <VuelidateError :errors="v$.item.firstName.$errors" />
      </div>

      <div class="field">
        <label for="surname">Příjmení</label>
        <InputText
          id="surname"
          v-model.trim="item.lastName"
          required="true"
          :class="{ 'p-invalid': submitted && !item.lastName }"
        />
        <VuelidateError :errors="v$.item.lastName.$errors" />
      </div>

      <div class="field">
        <label for="email">Email</label>
        <InputText
          id="email"
          v-model.trim="item.email"
          :class="{ 'p-invalid': submitted && !item.email }"
        />
        <VuelidateError :errors="v$.item.email.$errors" />
      </div>

      <div class="field">
        <label for="number">Číslo člena</label>
        <InputText
          id="number"
          v-model.trim="item.number"
          required="true"
          :class="{ 'p-invalid': submitted && !item.number }"
          pattern="^\d{5}$"
          maxlength="5"
          minlength="5"
        />
        <VuelidateError :errors="v$.item.number.$errors" />
      </div>

      <div class="field">
        <label for="breedingStation"> Chovatelská stanice </label>
        <Dropdown
          id="breedingStation"
          v-model="item.breedingStation"
          :options="stations"
          optionLabel="name"
          :filter="true"
          placeholder="Vybrat stanici"
          :showClear="true"
        />
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import TriStateCheckbox from "primevue/tristatecheckbox";
import Dropdown from "primevue/dropdown";
import memberService from "@/services/MemberService";
import StationService from "@/services/StationService";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import VuelidateError from "@/components/VuelidateError.vue";

export default {
  components: {
    VuelidateError,
    Button,
    Toolbar,
    InputText,
    Column,
    DataTable,
    Dialog,
    TriStateCheckbox,
    Dropdown,
  },
  setup() {
    return {
      v$: useVuelidate(),
      validationConfig: {
        $lazy: true,
        $autoDirty: true,
      },
    };
  },
  data() {
    return {
      infoDialog: false,
      items: null,
      item: {},
      selectedItems: null,
      submitted: false,
      stations: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        lastName: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        user: { value: null, matchMode: FilterMatchMode.EQUALS },
        "breedingStation.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      service: memberService,
    };
  },
  validations() {
    return {
      item: {
        firstName: { required },
        lastName: { required },
        email: { email },
        number: { required },
        breedingStation: { required },
      },
    };
  },
  created() {
    StationService.getAll()
      .then((response) => {
        this.stations = response.data;
      })
      .catch(() => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Problém při načítání chovatelských stanic.",
        });
      });
    this.fetchItems();
  },
  methods: {
    openNew() {
      this.item = {};
      this.submitted = false;
      this.infoDialog = true;
    },
    hideDialog() {
      this.infoDialog = false;
      this.submitted = false;
    },
    async saveItem() {
      this.submitted = true;
      if (await this.v$.$validate()) {
        if (this.item.id) {
          return this.updateItem();
        } else {
          return this.createItem();
        }
      }
    },
    editItem(item) {
      this.item = { ...item };
      this.infoDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    // CHANGE TO WORK PROPERLY
    fetchItems() {
      this.service
        .getAll()
        .then((response) => {
          this.items = response.data;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    createItem() {
      this.service
        .create(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Chovatel úspěšně vytvořen",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    updateItem() {
      this.service
        .update(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Chovatel úspěšně upraven",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "primevue/resources/themes/saga-blue/theme.css";
@import "primevue/resources/primevue.min.css";
@import "primeicons/primeicons.css";

.page {
  overflow-x: auto;
  flex-wrap: wrap;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
.true-icon {
  color: #256029;
}
.false-icon {
  color: #c63737;
}
</style>
