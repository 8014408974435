<template>
  <div class="form-demo">
    <div class="flex justify-content-center">
      <div class="card">
        <h1>{{ $t("litterRegistration.litterRegistration") }}</h1>
        <form @submit.prevent="handleSubmit" class="p-fluid">
          <LitterRegistration
            :data="sendData"
            :father-pedigree="true"
            :mother-pedigree="false"
            @get-litter="
              (getData) => {
                this.saveData(getData);
              }
            "
          />
          <Button
            type="submit"
            :label="$t('litterRegistration.submit')"
            class="mt-2"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import LitterService from "@/services/LitterService";
import Button from "primevue/button";
import LitterRegistration from "@/components/LitterRegistration.vue";

export default {
  components: {
    LitterRegistration,
    Button,
  },
  data() {
    return {
      sendData: null,
      data: {
        member: null,
        father: null,
        mother: null,
        differentFatherOwner: false,
        fatherOwner: null,
        litter: null,
        kittens: [],
      },
    };
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  methods: {
    async saveData(data) {
      this.data.member = data.member;
      this.data.father = data.father;
      this.data.mother = data.mother;
      this.data.differentFatherOwner = data.differentFatherOwner || false;
      this.data.fatherOwner = data.fatherOwner || null;
      this.data.litter = data.litter;
      this.data.kittens = data.kittens;
    },
    async handleSubmit() {
      if (await this.v$.$validate()) {
        LitterService.newLitter(this.data)
          .then(() => {
            this.data = null;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Úspěšně uloženo.",
            });
          })
          .catch((err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
            });
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: this.v$.$errors[0],
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    margin-bottom: 3em;

    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
