<template>
  <div>
    <!-- First name -->
    <div class="field">
      <div class="p-float-label">
        <InputText
          id="name"
          v-model="member.firstName"
          :class="{
            'p-invalid': v$.member.firstName.$invalid && submitted,
          }"
          required
        />
        <label
          for="name"
          :class="{
            'p-error': v$.member.firstName.$invalid && submitted,
          }"
        >
          Name
        </label>
      </div>
      <VuelidateError :errors="v$.member.firstName.$errors" />
    </div>

    <!-- Last name -->
    <div class="field">
      <div class="p-float-label">
        <InputText
          id="surname"
          v-model="member.lastName"
          :class="{
            'p-invalid': v$.member.lastName.$invalid && submitted,
          }"
          required
        />
        <label
          for="surname"
          :class="{ 'p-error': v$.member.lastName.$invalid && submitted }"
        >
          Surname
        </label>
      </div>
      <VuelidateError :errors="v$.member.lastName.$errors" />
    </div>

    <!-- Member number -->
    <div class="field">
      <div class="p-float-label">
        <InputText
          id="number"
          v-model="member.number"
          :class="{
            'p-invalid': v$.member.number.$invalid && submitted,
          }"
          required
          :input="findBreeder()"
        />
        <label
          for="number"
          :class="{ 'p-error': v$.member.number.$invalid && submitted }"
        >
          Member number
        </label>
      </div>
      <VuelidateError :errors="v$.member.number.$errors" />
    </div>

    <!-- Email -->
    <div v-if="member.requireEmail && showEmail" class="field">
      <div class="p-float-label p-input-icon-right">
        <i class="pi pi-envelope" />
        <InputText
          id="email"
          v-model="member.email"
          :class="{ 'p-invalid': v$.member.email.$invalid && submitted }"
          aria-describedby="email-error"
          :required="member.requireEmail"
          :input="sendBreeder()"
        />
        <label
          for="email"
          :class="{ 'p-error': v$.member.email.$invalid && submitted }"
          >Email</label
        >
      </div>
      <VuelidateError :errors="v$.member.email.$errors" />
    </div>
  </div>
</template>

<script>
import {
  email,
  required,
  numeric,
  maxLength,
  minLength,
  requiredIf,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import BreederService from "@/services/BreederService";
import VuelidateError from "@/components/VuelidateError.vue";

export default {
  components: {
    VuelidateError,
    InputText,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    showEmail: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      submitted: false,
      member: {
        requireEmail: false,
        firstName: null,
        lastName: null,
        number: null,
        email: null,
      },
    };
  },
  created() {
    if (!this.data) {
      this.member = {
        requireEmail: false,
        firstName: null,
        lastName: null,
        number: null,
        email: null,
      };
    } else {
      this.member = {
        requireEmail: false,
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        number: this.data.number,
        email: this.data.email,
      };
      this.findBreeder();
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validationConfig: {
    $lazy: true,
    $autoDirty: true,
  },
  validations() {
    return {
      member: {
        firstName: { required },
        lastName: { required },
        number: {
          required: required,
          numeric: numeric,
          maxLength: maxLength(5),
          minLength: minLength(5),
        },
        email: {
          email: email,
          required: requiredIf(this.showEmail && this.member.requireEmail),
        },
      },
    };
  },
  methods: {
    findBreeder() {
      if (
        this.member &&
        this.member.firstName &&
        this.member.lastName &&
        this.member.number &&
        this.member.number.length === 5 &&
        this.showEmail
      ) {
        BreederService.doesRequireEmail({
          firstName: this.member.firstName,
          lastName: this.member.lastName,
          number: this.member.number,
        })
          .then((response) => {
            this.member.requireEmail = response.data.requireEmail;
            if (!this.member.requireEmail) {
              this.sendBreeder();
            }
          })
          .catch((err) => {
            this.member.requireEmail = false;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
            });
          });
      } else this.member.requireEmail = false;
    },
    async sendBreeder() {
      if (await this.v$.$validate()) {
        this.$emit("getBreeder", this.member);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
