import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

export default {
  getAll() {
    return apiClient.get("/country/all");
  },
};
