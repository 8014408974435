import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Breed {
  code: string;
}

interface RequiredTestData {
  sex: string;
  breed: Breed;
  number: string;
  color: string;
}

interface Test {
  sex: string;
  breed: Breed;
  nameCs: string;
  code: string;
}

interface TestId extends Test {
  id: string;
}

export default {
  getRequired(data: RequiredTestData) {
    return apiClient.post("/test/required", data);
  },

  getAll() {
    return apiClient.get("/test");
  },

  create(data: Test) {
    return apiClient.post("/test", data);
  },

  update(data: TestId) {
    return apiClient.put("/test", data);
  },
};
