<template>
  <div v-if="this.loaded">
    <CatNumberInput
      :given-number="this.cat.number"
      :is-foreign="!this.cat.czech"
      :could-be-foreign="couldBeForeign"
      @get-number="
        (sendNumber, sendForeign) => {
          this.cat.number = sendNumber;
          this.cat.foreign = sendForeign;
          this.changeInput();
        }
      "
    />

    <div class="field" v-if="showName">
      <div class="p-float-label">
        <InputText
          id="name"
          v-model="cat.name"
          :class="{
            'p-invalid': v$.cat.name.$invalid,
          }"
          required
        />
        <label
          for="name"
          :class="{
            'p-error': v$.cat.name.$invalid,
          }"
        >
          Name
        </label>
      </div>
      <VuelidateError :errors="v$.cat.name.$errors" />
    </div>

    <EmsSelect
      v-if="getCat.ems"
      :given-ems="this.getCat.ems"
      @get-ems="
        (sendEms) => {
          this.cat.ems = sendEms;
          this.changeInput();
        }
      "
    />

    <EmsSelect
      v-if="!getCat.ems"
      @get-ems="
        (sendEms) => {
          this.cat.ems = sendEms;
          this.changeInput();
        }
      "
    />

    <!-- Chip -->
    <div class="field">
      <div class="p-float-label">
        <InputText
          :id="'catChip' + id"
          v-model="cat.chip"
          :class="{
            'p-invalid': v$.cat.chip.$invalid,
          }"
          required
          :input="this.changeInput()"
        />
        <label
          :for="'catChip' + id"
          :class="{
            'p-error': v$.cat.chip.$invalid,
          }"
        >
          Chip
        </label>
      </div>
      <VuelidateError :errors="v$.cat.chip.$errors" />
    </div>
    <div v-if="searchTests">
      <div v-if="doesRequirePedigree && requirePedigree">
        <TestInput
          :test="{
            nameCs: 'Rodokmen',
            code: 'Rodokmen',
          }"
          @upload-file="
            (catTest) => {
              this.cat.pedigree = catTest.file;
              this.submitCat();
            }
          "
        />
      </div>

      <div
        :key="requiredTest.code"
        v-for="(requiredTest, index) in this.requiredTests"
        style="margin-bottom: 2em"
      >
        <TestInput
          :test="requiredTest"
          @upload-file="
            (catTest) => {
              this.cat.catTests[index] = catTest;
              this.submitCat();
            }
          "
        />
      </div>
    </div>
    <div v-if="!searchTests">
      <h5>Rodokmen</h5>
      <Button
        icon="pi pi-file-pdf"
        class="p-button-rounded p-button-danger"
        @click="downloadPedigree()"
      />
      <h5 v-if="cat.catTests.length > 0">Testy</h5>
      <div
        :key="requiredTest.code"
        v-for="(requiredTest, index) in this.cat.catTests"
        style="margin-bottom: 2em"
      >
        <Button
          :id="index"
          icon="pi pi-file-pdf"
          class="p-button-rounded p-button-danger"
          @click="downloadFile(requiredTest.test)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import EmsSelect from "@/components/litterInput/EmsSelect";
import TestInput from "@/components/TestInput";
import TestService from "@/services/TestService";
import VuelidateError from "@/components/VuelidateError.vue";
import CatNumberInput from "@/components/CatNumberInput.vue";
import CatService from "@/services/CatService";
import Button from "primevue/button";
import CatTestService from "@/services/CatTestService";

let uid = 0;
export default {
  components: {
    CatNumberInput,
    VuelidateError,
    TestInput,
    EmsSelect,
    InputText,
    Button,
  },
  props: {
    couldBeForeign: {
      type: Boolean,
      default: false,
    },
    male: {
      type: Boolean,
      default: true,
    },
    searchTests: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: null,
    },
    requirePedigree: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    uid++;
    return {
      id: uid,
      requiredTests: [],
      doesRequirePedigree: false,
      loaded: false,
      getCat: {
        name: "",
        foreign: false,
        catTests: [],
        number: "",
        ems: null,
        chip: null,
        pedigree: null,
        czech: true,
      },
      cat: {
        name: "",
        foreign: false,
        catTests: [],
        number: "",
        ems: null,
        chip: null,
        pedigree: null,
        czech: true,
      },
      oldCat: JSON.stringify(this.cat),
    };
  },
  created() {
    if (this.data) {
      this.getCat = this.data;
      this.cat = this.data;
      this.getCat.foreign = this.data.czech;
      this.oldCat = JSON.stringify(this.getCat);
    }
    this.loaded = true;
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      cat: {
        chip: { required },
        name: { required: requiredIf(this.showName) },
      },
    };
  },
  methods: {
    async changeInput() {
      if (this.cat.ems && this.cat.ems.breed && this.cat.number)
        this.getRequiredTests();
      if (this.v$.$dirty) await this.submitCat();
    },
    getRequiredTests() {
      if (this.oldCat !== JSON.stringify(this.cat)) {
        if (this.searchTests) {
          TestService.getRequired({
            sex: this.male ? "m" : "f",
            breed: { code: this.cat.ems.breed.code },
            number: this.cat.number,
            color: this.cat.ems.color,
          })
            .then((response) => {
              this.requiredTests = response.data;
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
              });
            });
        }
        if (this.requirePedigree) {
          CatService.requirePedigree({
            number: this.cat.number,
          })
            .then((response) => {
              this.doesRequirePedigree = response.data;
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
              });
            });
        }
        this.oldCat = JSON.stringify(this.cat);
      }
    },
    async submitCat() {
      if (await this.v$.$validate()) {
        this.$emit("getCat", this.cat);
      }
    },
    downloadFile(test) {
      CatTestService.getFile(test.code, this.cat.number)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.setAttribute("display", "none");
          link.setAttribute("target", "_blank");
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.message,
          });
        });
    },
    downloadPedigree() {
      CatService.getFile(this.cat.number)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.setAttribute("display", "none");
          link.setAttribute("target", "_blank");
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
