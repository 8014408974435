import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegisterLitterView from "../views/RegisterLitterView.vue";
import MembersView from "../views/MembersView.vue";
import StationsView from "../views/StationsView.vue";
import WorkerRegisterLitterView from "../views/WorkerRegisterLitterView.vue";
import LitterRegistrationsView from "../views/LitterRegistrationView.vue";
import PedigreeView from "../views/PedigreeView.vue";
import BreedsView from "../views/BreedsView.vue";
import ColorsView from "../views/ColorsView.vue";
import TestsView from "../views/TestsView.vue";
import TitlesView from "../views/TitlesView.vue";
import SisterBreedsView from "../views/SisterBreedsView.vue";
import ImportView from "../views/ImportView.vue";
import CatsView from "@/views/CatsView.vue";
import CatsTitlesView from "@/views/CatsTitlesView.vue";
import CatTestsView from "@/views/CatTestsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/worker/litter/:id?",
    name: "pedigree",
    props: true,
    component: WorkerRegisterLitterView,
  },
  {
    path: "/litter/new",
    name: "Register Litter",
    component: RegisterLitterView,
  },
  {
    path: "/stations",
    name: "List stations",
    component: StationsView,
  },
  {
    path: "/members",
    name: "Chovatelé",
    component: MembersView,
  },
  {
    path: "/litter/registrations",
    name: "Litter registrations",
    component: LitterRegistrationsView,
  },
  {
    path: "/pedigree/print",
    name: "Pedigree print",
    component: PedigreeView,
  },
  {
    path: "/breeds",
    name: "Breeds",
    component: BreedsView,
  },
  {
    path: "/colors",
    name: "Colors",
    component: ColorsView,
  },
  {
    path: "/tests",
    name: "Tests",
    component: TestsView,
  },
  {
    path: "/titles",
    name: "Titles",
    component: TitlesView,
  },
  {
    path: "/breeds/sisters",
    name: "Sister breeds",
    component: SisterBreedsView,
  },
  {
    path: "/import",
    name: "Import",
    component: ImportView,
  },
  {
    path: "/cats",
    name: "Kočky",
    component: CatsView,
  },
  {
    path: "/cat/titles",
    name: "Tituly koček",
    component: CatsTitlesView,
  },
  {
    path: "/cat/tests",
    name: "Testy koček",
    component: CatTestsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
