<template>
  <div>
    <div class="field" v-if="couldBeForeign">
      <div class="field-checkbox">
        <Checkbox
          :inputId="'foreign' + this.id"
          name="foreign"
          v-model="foreign"
          :binary="true"
          style="margin-right: 1em"
        />
        <label :for="'foreign' + this.id"> Cizí číslo plemenné knihy </label>
      </div>
    </div>

    <!-- Foreign number -->
    <div class="field" v-if="foreign">
      <div class="p-float-label">
        <InputText
          id="number"
          v-model="number"
          :class="{
            'p-invalid': v$.number.$invalid,
          }"
          required
          :input="this.submitNumber()"
        />
        <label
          for="number"
          :class="{
            'p-error': v$.number.$invalid,
          }"
        >
          Number
        </label>
      </div>
      <VuelidateError :errors="v$.number.$errors" />
    </div>

    <!--Number-->
    <div class="field" v-if="!foreign">
      <div class="p-float-label">
        <InputText
          id="number"
          v-model="number"
          :class="{
            'p-invalid': v$.number.$invalid,
          }"
          required
          pattern="(\(CZ\)ČSCH) (LO|RX) ([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])\/([0-9][0-9])\/([A-Z][A-Z][A-Z])"
          :input="this.submitNumber()"
        />
        <label
          for="number"
          :class="{
            'p-error': v$.number.$invalid,
          }"
        >
          Number
        </label>
      </div>
      <VuelidateError :errors="v$.number.$errors" />
    </div>
  </div>
</template>

<script>
import { helpers, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import VuelidateError from "@/components/VuelidateError.vue";

const numberCheck = (foreign) =>
  helpers.withParams(
    { type: "numberCheck", number: foreign },
    (number) =>
      foreign ||
      (/^(\(CZ\)ČSCH) (LO|RX) ([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])\/([0-9][0-9])\/([A-Z][A-Z][A-Z])$/.test(
        number
      ) &&
        number.length > 19)
  );

let uid = 0;

export default {
  components: {
    VuelidateError,
    InputText,
    Checkbox,
  },
  props: {
    couldBeForeign: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    givenNumber: {
      type: String,
      default: null,
    },
    isForeign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    uid += 1;
    return {
      id: uid,
      foreign: this.isForeign,
      number: this.givenNumber ? this.givenNumber : "(CZ)ČSCH",
    };
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      number: {
        required: helpers.withMessage(
          "Číslo je vyžadováno.",
          requiredIf(this.required)
        ),
        number: helpers.withMessage(
          "Špatný formát čísla.",
          numberCheck(this.foreign)
        ),
      },
    };
  },
  methods: {
    async submitNumber() {
      if (this.v$.$dirty && (await this.v$.$validate())) {
        this.$emit("getNumber", this.number, this.foreign);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
