<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4"> </Toolbar>
      <DataTable
        ref="dt"
        :value="items"
        v-model:selection="selectedItems"
        dataKey="id"
        :loading="loading"
        :paginator="true"
        :rows="10"
        showGridlines
        v-model:filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Zobrazen {first}. až {last}. záznam z celkového počtu {totalRecords} záznamů"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :globalFilterFields="[
          'name',
          'pedigreeNumber',
          'breed',
          'color',
          'sex',
        ]"
        selectionMode="multiple"
        sortMode="multiple"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Kočky</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Hledat..."
              />
            </span>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          style="width: 3rem"
          :exportable="false"
        />

        <Column field="name" header="Jméno" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle jména"
            />
          </template>
        </Column>

        <Column field="pedigreeNumber" header="Číslo PK" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle čísla PK"
            />
          </template>
        </Column>

        <Column field="sex.label" header="Pohlaví" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle pohlaví"
            />
          </template>
        </Column>

        <Column field="color" header="Barva" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle barvy"
            />
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editItem(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import BreedService from "@/services/BreedService";
import CatService from "@/services/CatService";

export default {
  components: {
    Button,
    Toolbar,
    InputText,
    Column,
    DataTable,
  },
  setup() {
    return {
      v$: useVuelidate(),
      validationConfig: {
        $lazy: true,
        $autoDirty: true,
      },
    };
  },
  data() {
    return {
      loading: true,
      infoDialog: false,
      items: null,
      male: { sex: "m", label: "Kocour" },
      female: { sex: "f", label: "Kočka" },
      sexes: [
        { sex: "m", label: "Kocour" },
        { sex: "f", label: "Kočka" },
      ],
      item: {},
      selectedItems: null,
      submitted: false,
      breeds: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        pedigreeNumber: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "sex.label": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        color: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      service: CatService,
    };
  },
  validations() {
    return {
      item: {
        nameCs: { required },
        code: { required },
      },
    };
  },
  created() {
    BreedService.getAll()
      .then((response) => {
        this.breeds = response.data;
      })
      .catch(() => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Problém při načítání plemen",
        });
      });
    this.fetchItems();
  },
  methods: {
    openNew() {
      this.item = {};
      this.submitted = false;
      this.infoDialog = true;
    },
    hideDialog() {
      this.infoDialog = false;
      this.submitted = false;
    },
    async saveItem() {
      if (this.item.sex) {
        this.item.sex = this.item.sex.sex;
      }
      this.submitted = true;
      if (await this.v$.$validate()) {
        if (this.item.id) {
          return this.updateItem();
        } else {
          return this.createItem();
        }
      }
    },
    editItem(item) {
      this.item = { ...item };
      this.infoDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    // CHANGE TO WORK PROPERLY
    fetchItems() {
      this.service
        .getAll()
        .then((response) => {
          this.items = response.data.map((test) => {
            return {
              ...test,
              sex: test.sex
                ? test.sex === "m"
                  ? this.male
                  : this.female
                : null,
            };
          });
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "primevue/resources/themes/saga-blue/theme.css";
@import "primevue/resources/primevue.min.css";
@import "primeicons/primeicons.css";

.page {
  overflow-x: auto;
  flex-wrap: wrap;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
.true-icon {
  color: #256029;
}
.false-icon {
  color: #c63737;
}
</style>
