import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Cat {
  pedigreeNumber: string;
}

interface Test {
  code: string;
}

interface CatTest {
  cat: Cat;
  test: Test;
  file: File;
  value: string | null;
}

interface CatTestId {
  id: string;
  value: string | null;
}
export default {
  getAll() {
    return apiClient.get("/cat-test");
  },
  create(data: CatTest) {
    const test = {
      number: data.cat.pedigreeNumber,
      code: data.test.code,
      value: data.value || null,
      file: data.file,
    };
    return apiClient.post("/test/cat", test, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  update(data: CatTestId) {
    return apiClient.put("/cat-test", data);
  },
  getFile(code: string, number: string) {
    return apiClient.post(
      "/cat-test/file",
      { code: code, number: number },
      {
        responseType: "blob",
      }
    );
  },
};
