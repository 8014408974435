<template>
  <div v-if="this.loaded">
    <CatNumberInput
      :given-number="this.cat.number"
      :is-foreign="!this.cat.czech"
      :could-be-foreign="couldBeForeign"
      @get-number="
        (sendNumber, sendForeign) => {
          this.cat.number = sendNumber;
          this.cat.foreign = sendForeign;
          this.submitCat();
        }
      "
    />

    <div class="field">
      <div class="p-float-label">
        <InputText
          id="name"
          v-model="cat.name"
          :class="{
            'p-invalid': v$.cat.name.$invalid,
          }"
          required
        />
        <label
          for="name"
          :class="{
            'p-error': v$.cat.name.$invalid,
          }"
        >
          Name
        </label>
      </div>
      <VuelidateError :errors="v$.cat.name.$errors" />
    </div>

    <EmsSelect
      v-if="getCat.ems"
      :given-ems="this.getCat.ems"
      @get-ems="
        (sendEms) => {
          this.cat.ems = sendEms;
          this.submitCat();
        }
      "
    />

    <EmsSelect
      v-if="!getCat.ems"
      @get-ems="
        (sendEms) => {
          this.cat.ems = sendEms;
          this.submitCat();
        }
      "
    />
  </div>
</template>

<script>
import { requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import EmsSelect from "@/components/litterInput/EmsSelect";
import VuelidateError from "@/components/VuelidateError.vue";
import CatNumberInput from "@/components/CatNumberInput.vue";

export default {
  components: {
    CatNumberInput,
    VuelidateError,
    EmsSelect,
    InputText,
  },
  props: {
    couldBeForeign: {
      type: Boolean,
      default: false,
    },
    male: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      requiredTests: [],
      doesRequirePedigree: false,
      loaded: false,
      getCat: {
        name: "",
        sex: this.male ? "m" : "f",
        foreign: false,
        number: "",
        ems: null,
        czech: true,
      },
      cat: {
        name: "",
        sex: this.male ? "m" : "f",
        foreign: false,
        number: "",
        ems: null,
        czech: true,
      },
      oldCat: JSON.stringify(this.cat),
    };
  },
  created() {
    if (this.data) {
      this.getCat = this.data;
      this.cat = this.data;
      this.cat.number = this.data.pedigreeNumber;
      this.cat.ems = {
        breed: this.data.breed,
        color: { code: this.data.color },
      };
      this.cat.foreign = this.data.czech;
      this.oldCat = JSON.stringify(this.cat);
      this.getCat.foreign = this.data.czech;
      this.oldCat = JSON.stringify(this.getCat);
    }
    this.loaded = true;
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      cat: {
        name: { required: requiredIf(this.showName) },
      },
    };
  },
  methods: {
    async submitCat() {
      if (this.v$.$dirty && (await this.v$.$validate())) {
        this.$emit("getCat", this.cat);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
