import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Breed {
  code: string;
}

interface SisterBreed {
  breed: Breed;
  sister: Breed;
}

export default {
  getAll() {
    return apiClient.get("ems/breeds/sister");
  },
  create(data: SisterBreed) {
    return apiClient.post("ems/breeds/sister", data);
  },
};
