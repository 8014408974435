import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Title {
  nameCs: string;
  abbreviation: string;
  before: boolean;
}

interface TitleId extends Title {
  id: string;
}

export default {
  getAll() {
    return apiClient.get("/title/");
  },

  create(data: Title) {
    return apiClient.post("/title", data);
  },

  update(data: TitleId) {
    return apiClient.put("/title", data);
  },
};
