<template>
  <div v-for="error of this.errors" :key="error.$uid">
    <small class="p-error">
      {{ error.$message }}
    </small>
  </div>
</template>

<script>
export default {
  name: "VuelidateError",
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
