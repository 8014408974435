import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Cat {
  pedigreeNumber: string;
}

interface Title {
  abbreviation: string;
}

interface CatTitle {
  cat: Cat;
  title: Title;
}

export default {
  getAll() {
    return apiClient.get("/cat-title");
  },
  create(data: CatTitle) {
    return apiClient.post("/cat-title", data);
  },
  getFile(data: CatTitle) {
    return apiClient.post("/cat-title/title-file", data, {
      responseType: "blob",
    });
  },
};
