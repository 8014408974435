
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {},
  created() {
    this.$toast.add({
      severity: "warn",
      summary: "Pozor",
      detail: "Testovací verze",
    });
  },
});
