<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Nový"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
        </template>
      </Toolbar>
      <DataTable
        ref="dt"
        :value="items"
        v-model:selection="selectedItems"
        dataKey="number"
        :paginator="true"
        :rows="10"
        showGridlines
        v-model:filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Zobrazen {first}. až {last}. záznam z celkového počtu {totalRecords} záznamů"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :globalFilterFields="['nameCs', 'code', 'category', 'subcategory']"
        selectionMode="multiple"
        sortMode="multiple"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Plemena</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Hledat..."
              />
            </span>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          style="width: 3rem"
          :exportable="false"
        />

        <Column field="nameCs" header="Název" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle názvu"
            />
          </template>
        </Column>

        <Column field="code" header="Kód" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle kódu"
            />
          </template>
        </Column>

        <Column field="category" header="Kategorie" :sortable="true">
          <template #filter="{ filterModel }">
            <InputNumber
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle kategorie"
            />
          </template>
        </Column>

        <Column field="subcategory" header="Subkategorie" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle subkategorie"
            />
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editItem(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="infoDialog"
      :style="{ width: '450px' }"
      header="Plemeno"
      :modal="true"
      class="p-fluid"
    >
      <div class="field">
        <label for="nameCs">Název</label>
        <InputText
          id="nameCs"
          v-model.trim="item.nameCs"
          required="true"
          :class="{ 'p-invalid': submitted && !item.nameCs }"
        />
        <VuelidateError :errors="v$.item.nameCs.$errors" />
      </div>

      <div class="field">
        <label for="code">Kód</label>
        <InputText
          id="code"
          v-model.trim="item.code"
          required="true"
          :class="{ 'p-invalid': submitted && !item.code }"
        />
        <VuelidateError :errors="v$.item.code.$errors" />
      </div>

      <div class="field">
        <label for="category">Kategorie</label>
        <InputNumber
          id="category"
          v-model="item.category"
          required="true"
          :class="{ 'p-invalid': submitted && !item.category }"
        />
        <VuelidateError :errors="v$.item.category.$errors" />
      </div>

      <div class="field">
        <label for="subcategory">Subkategorie</label>
        <InputText id="subcategory" v-model.trim="item.subcategory" />
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import BreedService from "@/services/BreedService";
import VuelidateError from "@/components/VuelidateError.vue";

export default {
  components: {
    VuelidateError,
    Button,
    Toolbar,
    InputText,
    InputNumber,
    Column,
    DataTable,
    Dialog,
  },
  setup() {
    return {
      v$: useVuelidate(),
      validationConfig: {
        $lazy: true,
        $autoDirty: true,
      },
    };
  },
  data() {
    return {
      infoDialog: false,
      items: null,
      item: {},
      selectedItems: null,
      submitted: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nameCs: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        code: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        category: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        subcategory: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      service: BreedService,
    };
  },
  validations() {
    return {
      item: {
        nameCs: { required },
        code: { required },
        category: { required },
      },
    };
  },
  created() {
    this.fetchItems();
  },
  methods: {
    openNew() {
      this.item = {};
      this.submitted = false;
      this.infoDialog = true;
    },
    hideDialog() {
      this.infoDialog = false;
      this.submitted = false;
    },
    async saveItem() {
      this.submitted = true;
      if (await this.v$.$validate()) {
        if (this.item.id) {
          return this.updateItem();
        } else {
          return this.createItem();
        }
      }
    },
    editItem(item) {
      this.item = { ...item };
      this.infoDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    // CHANGE TO WORK PROPERLY
    fetchItems() {
      this.service
        .getAll()
        .then((response) => {
          this.items = response.data;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    createItem() {
      this.service
        .create(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Chovatel úspěšně vytvořen",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    updateItem() {
      this.service
        .update(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Chovatel úspěšně upraven",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "primevue/resources/themes/saga-blue/theme.css";
@import "primevue/resources/primevue.min.css";
@import "primeicons/primeicons.css";

.page {
  overflow-x: auto;
  flex-wrap: wrap;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
.true-icon {
  color: #256029;
}
.false-icon {
  color: #c63737;
}
</style>
