<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Nový"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
        </template>
      </Toolbar>
      <DataTable
        ref="dt"
        :value="items"
        v-model:selection="selectedItems"
        dataKey="id"
        :loading="loading"
        :paginator="true"
        :rows="10"
        showGridlines
        v-model:filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Zobrazen {first}. až {last}. záznam z celkového počtu {totalRecords} záznamů"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :globalFilterFields="['nameCs', 'code', 'group', 'breed.code']"
        selectionMode="multiple"
        sortMode="multiple"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Barvy</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Hledat..."
              />
            </span>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          style="width: 3rem"
          :exportable="false"
        />

        <Column field="code" header="Kód" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle kódu"
            />
          </template>
        </Column>

        <Column field="nameCs" header="Název" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle názvu"
            />
          </template>
        </Column>

        <Column field="group" header="Grupa" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle grupy"
            />
          </template>
        </Column>

        <Column field="breed.code" header="Plemeno" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle plemena"
            />
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editItem(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="infoDialog"
      :style="{ width: '450px' }"
      header="Plemeno"
      :modal="true"
      class="p-fluid"
    >
      <div class="field">
        <label for="nameCs">Název</label>
        <InputText
          id="nameCs"
          v-model.trim="item.nameCs"
          required="true"
          :class="{ 'p-invalid': submitted && !item.nameCs }"
        />
        <VuelidateError :errors="v$.item.nameCs.$errors" />
      </div>

      <div class="field">
        <label for="code">Kód</label>
        <InputText
          id="code"
          v-model.trim="item.code"
          required="true"
          pattern="^(am|cm|em|pm|\*m|n|a|b|c|d|e|f|g|h|j|o|p|q|r|t|w|\*|)([sy])?( 0([123459]))?(( 1([12]))|( 2([12345])))?( 3([123]))?( 5([1234]))?( 6([1234567]))?( 7([12]))?( 8([1234]))?"
          :class="{ 'p-invalid': submitted && !item.code }"
        />
        <VuelidateError :errors="v$.item.code.$errors" />
      </div>

      <div class="field">
        <label for="group">Grupa</label>
        <InputText id="group" v-model.trim="item.group" />
      </div>

      <div class="field">
        <label for="breed"> Plemeno </label>
        <Dropdown
          id="breed"
          v-model="item.breed"
          :options="breeds"
          optionLabel="code"
          :filter="true"
          placeholder="Vybrat plemeno"
          :showClear="true"
        />
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import BreedService from "@/services/BreedService";
import ColorService from "@/services/ColorService";
import VuelidateError from "@/components/VuelidateError.vue";

export default {
  components: {
    VuelidateError,
    Button,
    Toolbar,
    InputText,
    Column,
    DataTable,
    Dialog,
    Dropdown,
  },
  setup() {
    return {
      v$: useVuelidate(),
      validationConfig: {
        $lazy: true,
        $autoDirty: true,
      },
    };
  },
  data() {
    return {
      loading: true,
      infoDialog: false,
      items: null,
      item: {},
      selectedItems: null,
      submitted: false,
      breeds: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nameCs: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        code: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        group: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "breed.code": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      service: ColorService,
    };
  },
  validations() {
    return {
      item: {
        nameCs: { required },
        code: {
          required: required,
          pattern: helpers.regex(
            /^(am|cm|em|pm|\*m|n|a|b|c|d|e|f|g|h|j|o|p|q|r|t|w|\*|)([sy])?( 0([123459]))?(( 1([12]))|( 2([12345])))?( 3([123]))?( 5([1234]))?( 6([1234567]))?( 7([12]))?( 8([1234]))?$/
          ),
        },
        breed: { required },
      },
    };
  },
  created() {
    BreedService.getAll()
      .then((response) => {
        this.breeds = response.data;
      })
      .catch(() => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Problém při načítání plemen",
        });
      });
    this.fetchItems();
  },
  methods: {
    openNew() {
      this.item = {};
      this.submitted = false;
      this.infoDialog = true;
    },
    hideDialog() {
      this.infoDialog = false;
      this.submitted = false;
    },
    async saveItem() {
      this.submitted = true;
      if (await this.v$.$validate()) {
        if (this.item.id) {
          return this.updateItem();
        } else {
          return this.createItem();
        }
      }
    },
    editItem(item) {
      this.item = { ...item };
      this.infoDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    // CHANGE TO WORK PROPERLY
    fetchItems() {
      this.service
        .getAll()
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    createItem() {
      this.service
        .create(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Chovatel úspěšně vytvořen",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    updateItem() {
      this.service
        .update(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Chovatel úspěšně upraven",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "primevue/resources/themes/saga-blue/theme.css";
@import "primevue/resources/primevue.min.css";
@import "primeicons/primeicons.css";

.page {
  overflow-x: auto;
  flex-wrap: wrap;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
.true-icon {
  color: #256029;
}
.false-icon {
  color: #c63737;
}
</style>
