<template>
  <div>
    <!-- Kitten number -->
    <div class="field">
      <div class="p-float-label">
        <InputNumber
          id="kittenCount"
          inputId="withoutgrouping"
          v-model="litter.kittenCount"
          mode="decimal"
          :useGrouping="false"
          required
          :class="{
            'p-invalid': v$.litter.kittenCount.$invalid,
          }"
        />
        <label
          for="kittenCount"
          :class="{
            'p-error': v$.litter.kittenCount.$invalid,
          }"
        >
          Celkem narozeno koťat
        </label>
      </div>
      <VuelidateError :errors="v$.litter.kittenCount.$errors" />
    </div>

    <!-- Dead males -->
    <div class="field">
      <div class="p-float-label">
        <InputNumber
          id="deadMales"
          inputId="withoutgrouping"
          v-model="litter.deadMales"
          mode="decimal"
          :useGrouping="false"
          required
          :class="{
            'p-invalid': v$.litter.deadMales.$invalid,
          }"
        />
        <label
          for="deadMales"
          :class="{
            'p-error': v$.litter.deadMales.$invalid,
          }"
        >
          Dead males
        </label>
      </div>
      <VuelidateError :errors="v$.litter.deadMales.$errors" />
    </div>

    <!-- Dead females -->
    <div class="field">
      <div class="p-float-label">
        <InputNumber
          id="deadFemales"
          inputId="withoutgrouping"
          v-model="litter.deadFemales"
          mode="decimal"
          :useGrouping="false"
          required
          :class="{
            'p-invalid': v$.litter.deadFemales.$invalid,
          }"
        />
        <label
          for="deadFemales"
          :class="{
            'p-error': v$.litter.deadFemales.$invalid,
          }"
        >
          Dead females
        </label>
      </div>
      <VuelidateError :errors="v$.litter.deadFemales.$errors" />
    </div>

    <!-- Birthdate -->
    <div class="field">
      <div class="p-float-label">
        <Calendar
          id="date"
          v-model="litter.birthdate"
          :showIcon="true"
          required
          :class="{
            'p-invalid': v$.litter.birthdate.$invalid,
          }"
          dateFormat="dd.mm.yy"
        />
        <label for="date">Birthday</label>
      </div>
      <VuelidateError :errors="v$.litter.birthdate.$errors" />
    </div>

    <!-- Mating date -->
    <div class="field">
      <div class="p-float-label">
        <Calendar
          id="matingDate"
          v-model="litter.matingDate"
          :showIcon="true"
          required
          :class="{
            'p-invalid': v$.litter.matingDate.$invalid,
          }"
          :input="submitLitter()"
          dateFormat="dd.mm.yy"
        />
        <label for="matingDate">Mating date</label>
      </div>
      <VuelidateError :errors="v$.litter.matingDate.$errors" />
    </div>
  </div>
</template>

<script>
import { integer, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import VuelidateError from "@/components/VuelidateError.vue";

export default {
  components: {
    VuelidateError,
    InputNumber,
    Calendar,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      litter: {
        matingDate: null,
        birthdate: null,
        kittenCount: null,
        deadMales: null,
        deadFemales: null,
      },
    };
  },
  created() {
    if (this.data) {
      this.litter = {
        matingDate: new Date(this.data.matingDate),
        birthdate: new Date(this.data.birthdate),
        kittenCount: this.data.kittenCount,
        deadMales: this.data.deadMales,
        deadFemales: this.data.deadFemales,
      };
    }
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      litter: {
        matingDate: { required: required },
        birthdate: { required: required },
        kittenCount: { required: required, integer: integer },
        deadMales: { required: required, integer: integer },
        deadFemales: { required: required, integer: integer },
      },
    };
  },
  methods: {
    async submitLitter() {
      if (this.v$.$dirty && (await this.v$.$validate())) {
        this.$emit("getLitter", this.litter);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
