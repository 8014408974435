import axios from "axios";
import "@/interceptors/axios";

const apiClient = axios.create();

interface Color {
  code: string;
  nameCs: string;
  group: string;
  breed: { code: string };
}

interface ColorId extends Color {
  id: string;
}

export default {
  getAll() {
    return apiClient.get("/ems/colors");
  },

  create(data: Color) {
    return apiClient.post("/ems/color", data);
  },

  update(data: ColorId) {
    return apiClient.put("/ems/color", data);
  },
};
