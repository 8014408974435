import { createStore } from "vuex";
import axios from "axios";
import BreedService from "@/services/BreedService";

export default createStore({
  state: {
    user: null,
    breeds: null,
    loading: 0,
    loadingData: true,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      localStorage.setItem("user", JSON.stringify(userData));
      console.log(userData);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData["access_token"]}`;
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem("user");
      location.reload();
    },
    LOAD_BREEDS(state, data) {
      state.breeds = data;
      state.loadingData = false;
    },
    LOADING_ON(state) {
      state.loading++;
    },
    LOADING_OFF(state) {
      state.loading--;
    },
  },
  actions: {
    register({ commit }, credentials) {
      return axios.post("auth/signup", credentials).then(({ data }) => {
        commit("SET_USER_DATA", data);
      });
    },
    login({ commit }, credentials) {
      return axios.post("auth/signin", credentials).then(({ data }) => {
        commit("SET_USER_DATA", data);
      });
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
    },
    loadBreeds({ commit }) {
      return BreedService.getAll().then(({ data }) => {
        commit("LOAD_BREEDS", data);
      });
    },
    loadingOn({ commit }) {
      commit("LOADING_ON");
    },
    loadingOff({ commit }) {
      commit("LOADING_OFF");
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    breeds(state) {
      return state.breeds;
    },
    loading(state): boolean {
      return state.loading !== 0;
    },
    loadingData(state) {
      return state.loadingData;
    },
  },
});
