<template>
  <div>
    <!-- Name -->
    <div class="field">
      <div class="p-float-label">
        <InputText
          id="name"
          v-model="kitten.name"
          :class="{
            'p-invalid': v$.kitten.name.$invalid,
          }"
          required
        />
        <label
          for="name"
          :class="{
            'p-error': v$.kitten.name.$invalid,
          }"
        >
          Name
        </label>
      </div>
      <VuelidateError :errors="v$.kitten.name.$errors" />
    </div>

    <!-- Sex -->
    <h5>Sex</h5>
    <div class="field">
      <div class="p-float-label">
        <div class="field-radiobutton">
          <RadioButton
            inputId="male"
            name="sex"
            value="m"
            v-model="kitten.sex"
          />
          <label for="male">Male</label>
        </div>
      </div>
      <div class="p-float-label">
        <div class="field-radiobutton">
          <RadioButton
            inputId="female"
            name="sex"
            value="f"
            v-model="kitten.sex"
          />
          <label for="female">Female</label>
        </div>
      </div>
    </div>

    <EmsSelect
      v-if="kitten.ems"
      :given-ems="kitten.ems"
      @get-ems="(sendEms) => (this.kitten.ems = sendEms)"
    />

    <EmsSelect
      v-if="!kitten.ems"
      @get-ems="(sendEms) => (this.kitten.ems = sendEms)"
    />

    <!-- Chip -->
    <div class="field">
      <div class="p-float-label">
        <InputText
          :id="'chip' + id"
          v-model="kitten.chip"
          :class="{
            'p-invalid': v$.kitten.chip.$invalid,
          }"
          required
          :input="submitKitten()"
        />
        <label
          :for="'chip' + id"
          :class="{
            'p-error': v$.kitten.chip.$invalid,
          }"
        >
          Chip
        </label>
      </div>
      <VuelidateError :errors="v$.kitten.chip.$errors" />
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import EmsSelect from "@/components/litterInput/EmsSelect";
import VuelidateError from "@/components/VuelidateError.vue";

let uid = 0;
export default {
  components: {
    VuelidateError,
    EmsSelect,
    InputText,
    RadioButton,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    uid++;
    return {
      id: uid,
      kitten: {
        name: null,
        sex: "m",
        ems: null,
        chip: null,
      },
    };
  },
  created() {
    if (this.data) {
      this.kitten = this.data;
    }
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      kitten: {
        name: { required },
        sex: { required },
        ems: { required },
        chip: { required },
      },
    };
  },
  methods: {
    async submitKitten() {
      if (await this.v$.$validate()) {
        this.$emit("getKitten", this.kitten);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem 0;
  }
}
</style>
