import { createI18n } from "vue-i18n";
import cs from "./locales/cs-CZ.json";
import en from "./locales/en-US.json";

export default createI18n({
  legacy: false,
  locale: "cs",
  fallbackLocale: "cs",
  messages: {
    en: en,
    cs: cs,
  },
});
