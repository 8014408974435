<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Nový"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
        </template>
      </Toolbar>
      <DataTable
        ref="dt"
        :value="items"
        v-model:selection="selectedItems"
        dataKey="id"
        :loading="loading"
        :paginator="true"
        :rows="10"
        showGridlines
        v-model:filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Zobrazen {first}. až {last}. záznam z celkového počtu {totalRecords} záznamů"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :globalFilterFields="['test.code', 'value', 'cat.pedigreeNumber']"
        selectionMode="multiple"
        sortMode="multiple"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Testy koček</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Hledat..."
              />
            </span>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          style="width: 3rem"
          :exportable="false"
        />

        <Column field="test.code" header="Kód" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle kódu"
            />
          </template>
        </Column>

        <Column
          field="cat.pedigreeNumber"
          header="Číslo PK kočky"
          :sortable="true"
        >
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle čísla"
            />
          </template>
        </Column>

        <Column field="value" header="Hodnota" :sortable="true">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Hledat podle hodnoty"
            />
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editItem(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="infoDialog"
      :style="{ width: '450px' }"
      header="Test"
      :modal="true"
      class="p-fluid"
    >
      <div v-if="!updating">
        <div class="field">
          <div class="p-float-label">
            <Dropdown
              id="cat"
              v-model="item.cat"
              :options="cats"
              optionLabel="pedigreeNumber"
              :filter="true"
              :disabled="updating"
            />
            <label
              for="titles"
              :class="{ 'p-error': v$.item.cat.$invalid }"
              :style="item.cat ? '' : 'top: 35%'"
            >
              Kočka
            </label>
          </div>
          <VuelidateError :errors="v$.item.cat.$errors" />
        </div>

        <!--  Test  -->
        <div class="field">
          <div class="p-float-label">
            <Dropdown
              id="title"
              v-model="item.test"
              :options="tests"
              optionLabel="code"
              :filter="true"
              class="multiselect-custom"
              :disabled="updating"
            />
            <label
              for="title"
              :class="{ 'p-error': v$.item.test.$invalid }"
              :style="item.test ? '' : 'top: 35%'"
            >
              Test
            </label>
          </div>
          <VuelidateError :errors="v$.item.test.$errors" />
        </div>
      </div>

      <div class="field">
        <label for="value">Hodnota</label>
        <InputText
          id="nameCs"
          v-model.trim="item.value"
          required="true"
          :class="{ 'p-invalid': submitted && !item.value }"
        />
      </div>

      <div v-if="!updating">
        <h5>Soubor</h5>
        <div class="field">
          <div class="p-float-label">
            <FileUpload
              mode="basic"
              name="file"
              accept=".pdf, image/*"
              :chooseLabel="testLabel"
              :customUpload="true"
              :auto="true"
              @uploader="emulateUpload"
              @upload="emulateUpload"
            />
          </div>
        </div>
        <VuelidateError :errors="v$.item.file.$errors" />
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import BreedService from "@/services/BreedService";
import TestService from "@/services/TestService";
import VuelidateError from "@/components/VuelidateError.vue";
import CatTestService from "@/services/CatTestService";
import CatService from "@/services/CatService";

export default {
  components: {
    VuelidateError,
    Button,
    Toolbar,
    InputText,
    Column,
    DataTable,
    Dialog,
    Dropdown,
    FileUpload,
  },
  setup() {
    return {
      v$: useVuelidate(),
      validationConfig: {
        $lazy: true,
        $autoDirty: true,
      },
    };
  },
  data() {
    return {
      loading: true,
      infoDialog: false,
      testLabel: "Soubor",
      items: null,
      cats: [],
      tests: [],
      updating: false,
      male: { sex: "m", label: "Kocour" },
      female: { sex: "f", label: "Kočka" },
      sexes: [
        { sex: "m", label: "Kocour" },
        { sex: "f", label: "Kočka" },
      ],
      item: {},
      selectedItems: null,
      submitted: false,
      breeds: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        value: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "cat.pedigreeNumber": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "test.code": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      service: CatTestService,
    };
  },
  validations() {
    return {
      item: {
        test: { required },
        cat: { required },
        file: { required: requiredIf(!this.updating) },
      },
    };
  },
  created() {
    BreedService.getAll()
      .then((response) => {
        this.breeds = response.data;
      })
      .catch(() => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Problém při načítání plemen",
        });
      });
    this.fetchItems();
  },
  methods: {
    openNew() {
      this.updating = false;
      this.item = {};
      this.submitted = false;
      this.infoDialog = true;
    },
    hideDialog() {
      this.infoDialog = false;
      this.submitted = false;
      this.updating = false;
      this.testLabel = "Soubor";
    },
    async saveItem() {
      if (this.item.sex) {
        this.item.sex = this.item.sex.sex;
      }
      this.submitted = true;
      if (await this.v$.$validate()) {
        if (this.item.id) {
          return this.updateItem();
        } else {
          return this.createItem();
        }
      }
    },
    editItem(item) {
      this.updating = true;
      this.item = { ...item };
      this.infoDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    // CHANGE TO WORK PROPERLY
    fetchItems() {
      this.service
        .getAll()
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
      CatService.getAll()
        .then((response) => {
          this.cats = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
      TestService.getAll()
        .then((response) => {
          this.tests = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createItem() {
      this.service
        .create(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Test úspěšně vytvořen",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    updateItem() {
      this.service
        .update(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Test úspěšně upraven",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    emulateUpload(event) {
      this.item.file = event.files[0];
      this.testLabel = this.item.file.name;
      this.$emit("uploadFile", { ...this.test, file: this.file });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "primevue/resources/themes/saga-blue/theme.css";
@import "primevue/resources/primevue.min.css";
@import "primeicons/primeicons.css";

.field {
  margin-top: 1.5rem;
}

.page {
  overflow-x: auto;
  flex-wrap: wrap;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
.true-icon {
  color: #256029;
}
.false-icon {
  color: #c63737;
}
</style>
