import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./interceptors/axios";
import i18n from "./i18n";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";

import csPrimeVue from "./locales/primevue/cs-CZ.json";

createApp({
  extends: App,
  created() {
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USER_DATA", userData);
    }
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("logout");
        }
        return Promise.reject(error);
      }
    );
  },
})
  .use(i18n)
  .use(PrimeVue, { ...csPrimeVue })
  .use(store)
  .use(router)
  .use(ToastService)
  .mount("#app");
