<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="New"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
        </template>
      </Toolbar>
      <DataTable
        ref="dt"
        :value="items"
        v-model:selection="selectedItems"
        dataKey="id"
        :paginator="true"
        :rows="10"
        showGridlines
        v-model:filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :globalFilterFields="['created', 'status', 'kittens']"
        selectionMode="multiple"
        sortMode="multiple"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Zadané krycí listy</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Search..."
              />
            </span>
          </div>
        </template>

        <Column
          field="created"
          header="Vytvořeno"
          dataType="date"
          :sortable="true"
        >
          <template #body="{ data }">
            {{
              data.created.toLocaleDateString("cs-CZ", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
            }}
          </template>
          <template #filter="{ filterModel }">
            <Calendar
              v-model="filterModel.value"
              dateFormat="dd.mm.yy"
              placeholder="dd.mm.yyyy"
            />
          </template>
        </Column>

        <Column field="status" header="Status">
          <template #body="{ data }">
            <span :class="'customer-badge status-' + data.status">{{
              data.status
            }}</span>
          </template>
          <template #filter="{ filterModel }">
            <Dropdown
              v-model="filterModel.value"
              :options="statuses"
              placeholder="Status"
              class="p-column-filter"
              :showClear="true"
            >
              <template #value="slotProps">
                <span
                  :class="'customer-badge status-' + slotProps.value"
                  v-if="slotProps.value"
                  >{{ slotProps.value }}</span
                >
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="kittens"
          header="Počet koťat"
          :sortable="true"
          dataType="numeric"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel }">
            <Slider
              v-model="filterModel.value"
              range
              class="m-3"
              :min="0"
              :max="maxKittens"
            />
            <div class="flex align-items-center justify-content-between px-2">
              <span>
                {{ filterModel.value ? filterModel.value[0] : 0 }}
              </span>
              <span>
                {{ filterModel.value ? filterModel.value[1] : maxKittens }}
              </span>
            </div>
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <RouterLink
              :to="{ name: 'pedigree', params: { id: slotProps.data.id } }"
              style="text-decoration: none"
            >
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="() => {}"
              />
            </RouterLink>
          </template>
        </Column>

        <Column :exportable="false" header="Rodokmeny" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              v-if="
                slotProps.data.status === 'processed' ||
                slotProps.data.status === 'printed' ||
                slotProps.data.status === 'finished'
              "
              icon="pi pi-file-pdf"
              class="p-button-rounded p-button-danger mr-2"
              @click="downloadPedigrees(slotProps.data.id)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Slider from "primevue/slider";
import useVuelidate from "@vuelidate/core";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";

import LitterService from "@/services/LitterService";

export default {
  components: {
    Button,
    Toolbar,
    InputText,
    Column,
    DataTable,
    Slider,
    Dropdown,
    Calendar,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      items: null,
      item: {},
      selectedItems: null,
      submitted: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        created: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        kittens: { value: null, matchMode: FilterMatchMode.BETWEEN },
      },
      statuses: [
        "saved",
        "send",
        "opened",
        "returned",
        "resend",
        "processed",
        "printed",
        "finished",
      ],
      service: LitterService,
    };
  },
  created() {
    this.$store.dispatch("loadingOn");
    this.fetchItems();
  },
  computed: {
    maxKittens() {
      return Math.max(...this.items.map((o) => o.members)) || 0;
    },
  },
  methods: {
    openNew() {
      this.$router.push("/litter/new");
    },
    hideDialog() {
      this.infoDialog = false;
      this.submitted = false;
    },
    async saveItem() {
      this.submitted = true;
      if (await this.v$.$validate()) {
        if (this.item.id) {
          return this.updateItem();
        } else {
          return this.createItem();
        }
      }
    },
    editItem(item) {
      this.item = { ...item };
      this.infoDialog = true;
    },
    confirmDeleteOne(item) {
      this.item = item;
      this.deleteOneDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteMoreDialog = true;
    },
    formatDate(date) {
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      return date.getFullYear() + "-" + month + "-" + day;
    },
    // CHANGE TO WORK PROPERLY
    fetchItems() {
      this.service
        .getAll()
        .then((response) => {
          this.items = response.data;
          this.items.forEach((item) => (item.created = new Date(item.created)));
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data,
          });
        })
        .finally(() => this.$store.dispatch("loadingOff"));
    },
    deleteItem() {
      this.service
        .deleteOne(this.item)
        .then(() => {
          this.deleteOneDialog = false;
          this.item = {};
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Deleted",
          });
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
      this.deleteOneDialog = false;
      this.item = {};
      this.fetchItems();
    },
    deleteItems() {
      this.service
        .deleteMore(this.selectedItems)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Products Deleted",
          });
          this.fetchItems();
          this.selectedItems = null;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
          this.fetchItems();
        });
      this.deleteMoreDialog = false;
    },
    createItem() {
      this.service
        .create(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Organization created",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    updateItem() {
      this.service
        .update(this.item)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Organization updated",
          });
          this.infoDialog = false;
          this.item = {};
          this.fetchItems();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        });
    },
    async downloadPedigrees(id) {
      this.$store.dispatch("loadingOn");
      LitterService.getFile(id)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.setAttribute("display", "none");
          link.setAttribute("target", "_blank");
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
          });
        })
        .finally(() => this.$store.dispatch("loadingOff"));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "primevue/resources/themes/saga-blue/theme.css";
@import "primevue/resources/primevue.min.css";
@import "primeicons/primeicons.css";

.page {
  overflow-x: auto;
  flex-wrap: wrap;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
