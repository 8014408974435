<template>
  <h5>{{ test.nameCs }}</h5>
  <div class="field">
    <div class="p-float-label">
      <FileUpload
        mode="basic"
        :name="test.code"
        accept=".pdf, image/*"
        :chooseLabel="testLabel"
        :customUpload="true"
        :auto="true"
        @uploader="emulateUpload"
        @upload="emulateUpload"
      />
    </div>
  </div>
  <VuelidateError :errors="v$.file.$errors" />
</template>

<script>
import FileUpload from "primevue/fileupload";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import VuelidateError from "@/components/VuelidateError.vue";

export default {
  name: "TestInput",
  components: { VuelidateError, FileUpload },
  props: {
    test: {
      type: Object,
      required: true,
    },
  },
  emits: ["uploadFile"],
  data() {
    return {
      testLabel: null,
      file: null,
    };
  },
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true,
    }),
  }),
  validations() {
    return {
      file: { required },
    };
  },
  created() {
    if (!this.testLabel) {
      this.testLabel = this.$primevue.config.locale.choose;
    }
  },
  methods: {
    emulateUpload(event) {
      this.file = event.files[0];
      this.testLabel = this.file.name;
      this.$emit("uploadFile", { ...this.test, file: this.file });
    },
  },
};
</script>

<style scoped></style>
